import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, notification } from 'antd';
import { useUpdateClientMutation } from '../../features/api/apiSlice';
function ClientInfoEditModal({ state, setState, userData, rateTemplates }) {
    const [updateClient, { isLoading, isError, error, isSuccess }] = useUpdateClientMutation();
    const [comments1Hidden, setComments1Hidden] = useState(true);
    useEffect(() => {
        if (isError) {
            if (error.data && 'uid' in error.data) {
                notification.error({
                    message: "Error",
                    description: '客户ID已存在',
                });
            } else if (error.data && 'non_field_errors' in error.data) {
                notification.error({
                    message: "Error",
                    description:error.data.non_field_errors,
                });
                setState(prevState => ({
                    ...prevState,
                    open: false,
                }))
            } else {
                notification.error({
                    message: "Error",
                    description: "Unknown error",
                });
            }
        }
    }, [isError])
    useEffect(() => {
        if (state.comments1 === 'other') {
            setComments1Hidden(false);
            setState(prevState => ({
                ...prevState,
                comments1Other: prevState.comments1Other ?? '',
            }))
        } else {
            setComments1Hidden(true);
            setState(prevState => ({
                ...prevState,
                comments1Other: undefined,
            }))
        }
    }, [state.comments1])
    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: "Success",
                description: "客户信息更新成功",
            });
            setState(prevState => ({
                ...prevState,
                open: false,
            }))
        }
    }, [isSuccess])
    const handleCancel = () => {
        setState(prevState => ({
            ...prevState,
            open: false,
        }))
    }
    const handleOk = () => {
        const data = {
            id: state.id,
            uid: state.uid,
            username: state.username,
            is_active: state.status,
            user: state.userAssignedTo,
            rate_template: state.rateTemplate,
            contact: state.contact,
            comments1: state.comments1 === 'other' ? state.comments1Other : state.comments1,
            updated_at: state.updated_at,
        }
        updateClient(data);
    }
    return (
        <Modal
            title="编辑客户信息"
            open={state.open}
            onOk={handleOk}
            onCancel={handleCancel}
            confirmLoading={isLoading}
            okText="保存"
            cancelText="取消"
        >
            <Form
                labelCol={{
                    span: 5,
                }}
                wrapperCol={{
                    span: 19,
                }}
                style={{
                    marginTop: '20px'
                }}>
                <Form.Item label="客户ID">
                    <Input
                        value={state.uid}
                        onChange={(e) => {
                            const value = e.target.value;
                            setState(prevState => ({ ...prevState, uid: value }))
                        }}
                    />
                </Form.Item>
                <Form.Item label="客户名">
                    <Input
                        value={state.username}
                        onChange={(e) => {
                            const value = e.target.value;
                            setState(prevState => ({ ...prevState, username: value }))
                        }}
                    />
                </Form.Item>
                <Form.Item label="客户分类">
                    <Select
                        allowClear
                        value={state.rateTemplate}
                        onChange={(value) => {
                            setState(prevState => ({ ...prevState, rateTemplate: value }))
                        }}
                    >
                        {rateTemplates && rateTemplates.map((rateTemplate) => (
                            <Select.Option key={rateTemplate.id} value={rateTemplate.id}>
                                {rateTemplate.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="客户状态">
                    <Select
                        value={state.status}
                        onChange={(value) => {
                            setState(prevState => ({ ...prevState, status: value }))
                        }}>
                        <Select.Option value="1">Active</Select.Option>
                        <Select.Option value="0">Idle</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="客户对接人">
                    <Select
                        value={state.contact}
                        onChange={(value) => {
                            setState(prevState => ({ ...prevState, contact: value }))
                        }}
                    >
                        {userData && userData.map(user => (
                            <Select.Option key={user.id} value={user.id}>
                                {user.username}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="账单负责人">
                    <Select
                        value={state.userAssignedTo}
                        onChange={(value) => {
                            setState(prevState => ({ ...prevState, userAssignedTo: value }))
                        }}
                    >
                        {userData && userData.map(user => (
                            <Select.Option key={user.id} value={user.id}>
                                {user.username}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item label="业务类型">
                    <Select
                        allowClear
                        value={state.comments1}
                        onChange={(value) => {
                            setState(prevState => ({ ...prevState, comments1: value }))
                        }}
                    >
                        <Select.Option value="API">API</Select.Option>
                        <Select.Option value="Prelabeled">Prelabeled</Select.Option>
                        <Select.Option value="易仓一件代发">易仓一件代发</Select.Option>
                        <Select.Option value="other">other</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item wrapperCol={{span:19, offset: 5}} hidden={comments1Hidden}>
                    <Input
                        value={state.comments1Other}
                        onChange={(e) => {
                            const value = e.target.value;
                            setState(prevState => ({ ...prevState, comments1Other: value }))
                        }}
                    />

                </Form.Item>
                <Form.Item label="备注">
                    <Input
                        value={state.comments2}
                        onChange={(e) => {
                            const value = e.target.value;
                            setState(prevState => ({ ...prevState, comments2: value }))
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ClientInfoEditModal;