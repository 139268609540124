import React, { useState, useEffect } from 'react';
import { Space, Button, Table, notification } from 'antd';
import { renderDateTime } from '../utils';
import { 
    useGetBillingInvoiceQuery, 
    useGetLogisticsSupplierQuery, 
    useDeleteBillingInvoiceMutation,
    useGetBillingFileByInvoiceIdQuery,
    useDeleteTestFilesMutation,
} from '../../features/api/apiSlice';
import CreateNewBatchModal from './createNewBatchModal';
import DisplayBillingFileModal from './DisplayBillingFileModal';
function CreateNewBatchPage () {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [displayFilesModalOpen, setDisplayFilesModalOpen] = useState(false);
    const [currentInvoiceId, setCurrentInvoiceId] = useState(null);
    const { data, error, isLoading, isFetching, refetch } = useGetBillingInvoiceQuery({page_size: pageSize, curr_page: currentPage});
    const { data: logisticsSuppliers } = useGetLogisticsSupplierQuery();
    const { data: billingFiles, isLoading: billingFilesLoading, isFetching: billingFilesFetching, refetch: billingFileRefetch } = useGetBillingFileByInvoiceIdQuery(currentInvoiceId);
    const [deleteBillingInvoice] = useDeleteBillingInvoiceMutation();
    const [deleteAllTestFiles,
        {
            isLoading: deleteTestFilesLoading,
            isFetching: deleteTestFilesFetching,
            isSuccess: deleteTestFilesSuccess,
            isError: deleteTestFilesError,
        }] = useDeleteTestFilesMutation();

    const deleteButtonHandler = (record) => {
        deleteBillingInvoice({"id": record.id});
    }
    const displayFilesButtonHandler = (record) => {
        if (record.id === currentInvoiceId) {
            setDisplayFilesModalOpen(true);
            billingFileRefetch();
            return;
        }
        setCurrentInvoiceId(record.id);
        setDisplayFilesModalOpen(true);
    }
    const deleteTestFilesButtonHandler = () => {
        deleteAllTestFiles();
        notification.info({
            message: 'Deleting',
            description: 'it could take a while, please wait...',
            duration: 1,
        })
    }
    useEffect(() => {
        refetch();
    }, [])
    useEffect(() => {
        if (deleteTestFilesSuccess) {
            notification.success({
                message: 'Success',
                description: '所有测试数据已被删除',
            })
        }
        if (deleteTestFilesError) {
            notification.error({
                message: 'Error',
                description: '删除测试数据失败',
            })
        }
    }, [deleteTestFilesSuccess, deleteTestFilesError])
    const columns = [
        {
            title: '导入批次编号',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '提交人',
            dataIndex: ['user', 'username'],
            key: 'user',
        },
        {
            title: '提交时间',
            dataIndex: 'created_at',
            key: 'created_at',
            render: renderDateTime,
        },
        {
            title: '进度',
            dataIndex: 'progress',
            key: 'progress',
        },
        {
            title: '耗时',
            dataIndex: 'completed_time',
            key: 'completed_time',
            render: (time) => {
                return time ? time : 'N/A';
            }
        },
        {
            title: 'Net Charge Amount',
            dataIndex: 'charge_amount',
            key: 'charge_amount',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record) => {
                return (
                    <Space size="small">
                        <Button type="link" onClick={() => displayFilesButtonHandler(record)}>查看所有文件</Button>
                        <Button type="link" onClick={() => deleteButtonHandler(record)}>删除</Button>
                    </Space>
                )
            }
        }
    ]

    return (
        <div>
            <Space style={{ display: 'flex'}} direction='vertical'>
            <Space style={{ display: 'flex'}} direction='horizontal' size='middle'>
            <h2>导入批次列表</h2>
            <Button type="primary" onClick={() => setCreateModalOpen(true)}>新建导入批次</Button>
            <Button onClick={refetch}>刷新</Button>
            <Button disabled={true} onClick={deleteTestFilesButtonHandler} loading={deleteTestFilesLoading || deleteTestFilesFetching}>删除测试文件</Button>
            </Space>
            <Table
                rowKey={record => record.id}
                loading={isLoading || isFetching}
                columns={columns}
                dataSource={data?.results}
                pagination={{
                    showSizeChanger: true,
                    pageSize: pageSize,
                    current: currentPage,
                    total: data?.count,
                    onChange: (page, pageSize) => {
                        setCurrentPage(page);
                        setPageSize(pageSize);
                    },
                    onShowSizeChange: (current, size) => {
                        setCurrentPage(current);
                        setPageSize(size);
                    },
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                scroll={{ x: 1500 }}
                />
            </Space>
            <CreateNewBatchModal 
                open={createModalOpen}
                setOpen={setCreateModalOpen}
                logisticsSuppliers={logisticsSuppliers}
                />
            <DisplayBillingFileModal
                open={displayFilesModalOpen}
                setOpen={setDisplayFilesModalOpen}
                data={billingFiles?.data}
                loading={billingFilesLoading || billingFilesFetching}
            />
        </div>
    )
}

export default CreateNewBatchPage;