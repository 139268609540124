import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, Outlet } from "react-router-dom";

const ProtectedRoutes = () => {
  const navigate = useNavigate();
  const auth = useSelector((store) => store.auth.isAuthenticated);
  const isLoggedIn = () => {
    if (!auth) {
      navigate('/login');
    }
  }
  useEffect(() => {
    isLoggedIn();
  },[])
  return <Outlet />;
};



export default ProtectedRoutes;