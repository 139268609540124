import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Space, theme, Select, DatePicker, notification } from 'antd';
import moment from 'moment-timezone';
import axios from 'axios';
const { RangePicker } = DatePicker;
const NonStandardServiceSearchBar = ({ customerData, setSearchData, setCurrentPage, setPageSize, billingInvoiceNames }) => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const [downloading, setDownloading] = useState(false);
    const getFields = () => {
        const children = [];
        children.push(

            <Col span={8} key={1}>
                <Form.Item name="billToAccountNumber" label="Bill To Account Number">
                    <Input placeholder="请输入内容" />
                </Form.Item>
            </Col>,
            <Col span={8} key={2}>
                <Form.Item name="trackingNumber" label="Invoice Tracking Number">
                    <Input placeholder="请输入内容" />
                </Form.Item>
            </Col>,
            <Col span={8} key={3}>
                <Form.Item name="logisticsService" label="物流服务">
                    <Input placeholder="请输入内容" />
                </Form.Item>
            </Col>,
            <Col span={8} key={4}>
                <Form.Item name="invoiceNumber" label="Invoice Number">
                    <Input placeholder="请输入内容" />
                </Form.Item>
            </Col>,
            <Col span={8} key={5}>
                <Form.Item name="uid" label="UID">
                    <Select placeholder='请选择' allowClear style={{ textAlign: "left" }} mode='multiple'>
                        {customerData && customerData.map((item) => (
                            <Select.Option key={item.uid} value={item.uid}>{item.uid}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>,
            <Col span={8} key={6}>
                <Form.Item name="invoiceDate" label="Invoice Date">
                    <RangePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
                </Form.Item>
            </Col>,
            <Col span={8} key={7}>
                <Form.Item name="billingInvoice" label="导入批次编号">
                    <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        style={{ textAlign: "left", width: '100%' }}
                        filterOption={(input, option) =>
                            (option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }>
                        {billingInvoiceNames && billingInvoiceNames.map(billingInvoiceName => (
                            <Select.Option key={billingInvoiceName.id} value={billingInvoiceName.id} label={billingInvoiceName.name}>
                                {billingInvoiceName.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>

        );
        return children;
    };
    const downloadButtonHandler = () => {
        setDownloading(true);
        const searchData = form.getFieldsValue();
        if (searchData.invoiceDate) {
            searchData.invoiceDateAfter = moment(searchData.invoiceDate[0].toDate()).format('YYYY-MM-DD');
            searchData.invoiceDateBefore = moment(searchData.invoiceDate[1].toDate()).format('YYYY-MM-DD');
            delete searchData.invoiceDate;
        }
        // trim all values
        Object.keys(searchData).forEach(key => {
            if (typeof searchData[key] === 'string') {
                searchData[key] = searchData[key].trim();
            }
        });
        const searchParams = new URLSearchParams();
        const map = {
            "billToAccountNumber":"bill_to_account_number",
            "invoiceDateBefore": "invoice_date_before",
            "invoiceDateAfter": "invoice_date_after",
            "invoiceNumber": "invoice_number",
            "logisticsService": "logistics_service",
            "trackingNumber": "tracking_number",
            "uid": "uid",
            "pageSize": "page_size",
            "currentPage": "curr_page",
            "billingInvoice": "billing_invoice",
        }
        Object.keys(searchData).forEach(key => {
            if (searchData[key]) {
                searchParams.append(map[key], searchData[key]);
            }
        });
        // add isDownload = true
        searchParams.append('isDownload', true);

        axios.get("/accounts/get-non-standard-service/", {
            params: searchParams,
            withCredentials: true
        }).then((response) => {
            if (response.status === 200) {
                notification.success({
                    message: 'Success',
                    description: '导出成功,请前往下载管理页面获取文件',
                });
                setDownloading(false);
            } else {
                notification.error({
                    message: 'Error',
                    description: '导出失败,请联系管理员',
                });
                setDownloading(false);
            }
        }).catch((error) => {
            notification.error({
                message: 'Error',
                description: '导出失败,请联系管理员',
            });
            setDownloading(false);
        })
    }
    const onFinish = (values) => {
        const searchData = { ...values };
        if (searchData.invoiceDate) {
            searchData.invoiceDateAfter = moment(searchData.invoiceDate[0].toDate()).format('YYYY-MM-DD');
            searchData.invoiceDateBefore = moment(searchData.invoiceDate[1].toDate()).format('YYYY-MM-DD');
            delete searchData.invoiceDate;
        }
        // trim all values
        Object.keys(searchData).forEach(key => {
            if (typeof searchData[key] === 'string') {
                searchData[key] = searchData[key].trim();
            }
        });
        setSearchData(searchData);
        setCurrentPage(1);
        setPageSize(10);
    };

    return (
        <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
            <Row gutter={24}>{getFields()}</Row>
            <div
                style={{
                    textAlign: 'right',
                }}
            >
                <Space size="small">
                    <Button type="primary" loading={downloading} onClick={downloadButtonHandler}>
                        下载数据
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            form.resetFields();
                        }}
                    >
                        Clear
                    </Button>
                </Space>
            </div>
        </Form>
    );
};

export default NonStandardServiceSearchBar;