import React, {useEffect, useState} from 'react';
import {
    useAddNewFuelSurchargeMutation,
    useGetFuelSurchargeManagementQuery, useGetLogisticsServiceByNameQuery,
    useUpdateFuelSurchargeManagementMutation,
} from '../../features/api/apiSlice';
import {Button, notification, Space, Switch, Table} from 'antd';
import FuelSurchargeEditModal from "./FuelSurchargeEditModal";
import FuelSurchargeManagementSearchBar from "./FuelSurchargeManagementSearchBar";
import FuelSurchargeAddModal from "./FuelSurchargeAddModal";
function FuelSurchargeManagementPage() {
    const [searchData, setSearchData] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [updateEnableLoading, setUpdateEnableLoading] = useState(false);
    const [rows, setRows] = useState({});
    const [sorter, setSorter] = useState({
        field: 'eff_time',
        order: 'descend'
    });
    const { data, error, isLoading, isFetching, refetch } = useGetFuelSurchargeManagementQuery({...searchData, sorter, pageSize: pageSize, currentPage: currentPage});
    const { data: logisticsService } = useGetLogisticsServiceByNameQuery();
    const [fuelSurchargeEditModalVisible, setFuelSurchargeEditModalVisible] = useState(false);
    const [fuelSurchargeAddModalVisible, setFuelSurchargeAddModalVisible] = useState(false);
    const [AddFuelSurcharge, {
        isSuccess: isAFSSuccess,
        isError: isAFSError,
        error: AFSError,
        isLoading: isAFSLoading,
        isFetching: isAFSFetching
    }] = useAddNewFuelSurchargeMutation();
    useEffect(() => {
        if (isAFSSuccess) {
            notification.success({
                message: "Success",
                description: '新增燃油率成功!',
            })
            setFuelSurchargeAddModalVisible(false);
        }
        if (isAFSError) {
            notification.error({
                message: "Error",
                description: AFSError?.data?.error || "新增燃油率失败!",
            });
        }
    }, [isAFSError, isAFSSuccess])
    const columns = [
        {
            title: '服务名',
            dataIndex: 'service_name',
            key: 'service_name'
        },
        {
            title: '启用时间',
            dataIndex: 'eff_time',
            key: 'eff_time',
            sorter: true,
            sortDirections: ['descend', 'ascend'],
            defaultSortOrder: 'descend'
        },
        {
            title: '费率',
            dataIndex: 'rate',
            key: 'rate',
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Switch
                        checkedChildren="开启"
                        unCheckedChildren="关闭"
                        defaultChecked={record.status === '启用'}
                        loading={updateEnableLoading}
                        onChange={(checked) => handleUpdateEnable(record.id, checked ? "1" : "0")}
                    />
                </Space>
            ),
        },
    ];
    useEffect(() => {
        refetch();
    }, [])
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [searchData])
    const handleTableChange = (pagination, filters, sorter) => {
        setPageSize(pagination.pageSize)
        setCurrentPage(pagination.current)
        setSorter(sorter)
        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== pageSize) {
            console.log('ABC')
        }
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, selectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys);
        },
    };

    const [UpdateFuelSurcharge, {
        isSuccess: isUFSSuccess,
        isError: isUFSError,
        error: UFSError,
        isLoading: isUFSLoading,
        isFetching: isUFSFetching
    }] = useUpdateFuelSurchargeManagementMutation();
    useEffect(() => {
        if (isUFSSuccess) {
            notification.success({
                message: "Success",
                description: '修改成功!',
            });
            setFuelSurchargeEditModalVisible(false);
            setSelectedRowKeys([]);
            setRows({})
        }
        if (isUFSError) {
            notification.error({
                message: "Error",
                description: UFSError?.data?.error || "修改失败!",
            });
        }
    }, [isUFSSuccess, isUFSError])

    const getSelectedRowsData = () => {
        return data.results.filter(row => selectedRowKeys.includes(row.id));
    };

    useEffect(() => {
        if (selectedRowKeys.length >= 1) {
            setRows(getSelectedRowsData());
        }else {
            setRows({});
        }
    }, [selectedRowKeys])

    const fuelSurchargeEditButtonHandler = () => {
        if (selectedRowKeys.length !== 1) {
            return notification.error({
                message: "Error",
                description: "请选择一条记录",
            });
        }
        setFuelSurchargeEditModalVisible(true);
    }
    const handleUpdateEnable = (id, status) => {
        setUpdateEnableLoading(true);
        try {
            const formData = new FormData();
            formData.append('id', id);
            formData.append('status', status);
            UpdateFuelSurcharge(formData)
        }catch (e) {
            console.log(e)
        }finally {
            setUpdateEnableLoading(false);
        }
    }
    const fuelSurchargeEditSubmitHandler = (values) => {
        try {
            const formData = new FormData();
            formData.append('id', rows[0].id);
            formData.append('rate', values.rate);
            UpdateFuelSurcharge(formData)

        }catch (err){
            const err_msg = err.response?.data?.errors;
            notification.error({
                message: 'Error',
                description: err_msg,
            })
        }
    }
    const addNewFuelSurchargeButtonHandler = () => {
        setFuelSurchargeAddModalVisible(true);
    }
    const fuelSurchargeAddSubmitHandler = (values) => {
        const formData = new FormData();
        formData.append('service_name', values.service_name);
        // eff_time is a moment object, we only need the date
        formData.append('eff_time', values.eff_time.format('YYYY-MM-DD'));
        formData.append('rate', values.rate);
        AddFuelSurcharge(formData);
    }
    return (
        <div>
            <Space direction="vertical"
                size="middle"
                style={{
                    display: 'flex',
                }}>
                <FuelSurchargeManagementSearchBar
                    logisticsService={logisticsService}
                    setSearchData={setSearchData}
                    setCurrentPage={setCurrentPage}
                    setPageSize={setPageSize}
                />
                <Space direction="horizontal" style={{ display: 'flex' }}>
                    <Button type="primary" onClick={fuelSurchargeEditButtonHandler}>修改附加费</Button>
                    <Button type="primary" onClick={addNewFuelSurchargeButtonHandler}>新增燃油率</Button>
                    <Button onClick={refetch}>
                        刷新
                    </Button>
                </Space>
                <Table
                    rowSelection={rowSelection}
                    rowKey={(record) => record.id}
                    loading={isLoading || isFetching}
                    columns={columns}
                    dataSource={data?.results}
                    onChange={handleTableChange}
                    pagination={{
                        showSizeChanger: true,
                        pageSize: pageSize,
                        current: currentPage,
                        total: data ? data.count : 0,
                        onChange: (page, pageSize) => {
                            setCurrentPage(page);
                            setPageSize(pageSize);
                            setSelectedRowKeys([]);
                        },
                        onShowSizeChange: (current, size) => {
                            setCurrentPage(current);
                            setPageSize(size);
                            setSelectedRowKeys([]);
                        },
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    scroll={{ x: 1500 }}
                />
            </Space>
            <FuelSurchargeEditModal
                open={fuelSurchargeEditModalVisible}
                setOpen={setFuelSurchargeEditModalVisible}
                onSubmit={fuelSurchargeEditSubmitHandler}
                loading={isUFSFetching || isUFSLoading}
                success={isUFSSuccess}
                rows={rows}
            />
            <FuelSurchargeAddModal
                open={fuelSurchargeAddModalVisible}
                setOpen={setFuelSurchargeAddModalVisible}
                onSubmit={fuelSurchargeAddSubmitHandler}
                loading={isAFSLoading || isAFSFetching}
                success={isAFSSuccess}
            />
        </div>
    )
}
export default FuelSurchargeManagementPage;