import { configureStore } from '@reduxjs/toolkit'
import authReducer from './features/auth/authSlice'
import storage from 'redux-persist/lib/storage';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { apiSlice }  from './features/api/apiSlice';
const persistConfig = {
  key: 'auth',
  storage,
}
const persistedAuthReducer = persistReducer(persistConfig, authReducer);

const rootReducer = {
  auth: persistedAuthReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }).concat(apiSlice.middleware),
})

export const persistor = persistStore(store)