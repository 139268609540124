import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, notification } from 'antd';
import { useCreateRateTemplateMutation } from '../../features/api/apiSlice';
function RateTemplateCreateModal ({ open, setOpen }) {
    const [form] = Form.useForm();
    const [createRateTemplate, { isError, isLoading, error, isSuccess}] = useCreateRateTemplateMutation();
    const handleCancel = () => {
        setOpen(false);
    }
    useEffect(() => {
        if (isError) {
            console.log(error);
            notification.error({
                message: "Error",
                description: error?.data?.name ? error.data.name[0] : "创建失败 for no reason, 请联系管理员",
            })
        }
        if (isSuccess) {
            notification.success({
                message: "Success",
                description: "客户分类创建成功",
            });
            setOpen(false);
            form.resetFields();
        }
    }, [isError, isSuccess])

    const handleOk = () => {
        form.validateFields().then(values => {
            console.log(`values `, values);
            createRateTemplate(values);
        }).catch((info) => {
            console.log("Validate Failed:", info);
        });
    }

    return (
        <Modal title="添加新客户分类" open={open} onOk={handleOk} onCancel={handleCancel} confirmLoading={isLoading}>
            <Form
                form={form}
                labelCol={{
                    span: 5,
                }}
                wrapperCol={{
                    span: 19,
                }}
                style={{ marginTop: '20px', maxWidth: '100%' }}>
                <Form.Item
                    name="name"
                    label="客户分类名"
                    rules={[
                        {
                            required: true,
                            message: '请填写客户分类名!'
                        }
                    ]}
                    validateTrigger="onSubmit">
                    <Input />
                </Form.Item>
                <Form.Item name="user_group" label="客户分组" rules={[{ required: true, message: '请选择客户分组'}]}>
                    <Select>
                        <Select.Option value="1">Awesung</Select.Option>
                        <Select.Option value="2">Uparcel</Select.Option>
                    </Select>
                </Form.Item>            
            </Form>
        </Modal>
    )
}

export default RateTemplateCreateModal;