import React, { useEffect } from 'react';
import { Modal, Form, Input, Select, DatePicker, notification } from 'antd';
function FuelSurchargeAddModal ({ open, setOpen, onSubmit, loading, success}) {
    const [form] = Form.useForm();
    const handleOk = () => {
        form.validateFields().then(values => {
            onSubmit(values);
        }).catch(error => {
            console.log(error);
        })
    }
    useEffect(() => {
        if (success) {
            form.resetFields();
        }
    })
    const disableAllDayExceptMonday = (current) => {
        return current.day() !== 1;
    }
    const twoDecimalPlacesOnly = (rule, value) => {
        if (!/^\d+(\.\d{1,2})?$/.test(value)) {
            return Promise.reject('仅支持两位小数');
        }
        return Promise.resolve();
    }
    return (
        <Modal
            title="新增燃油率"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={handleOk}
            centered
            confirmLoading={loading}
        >
            <Form
                form={form}
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 18,
                }}
                style={{
                    marginTop: '20px',
                    marginLeft: '15%',
                    marginRight: '20%',
                    maxWidth: '80%'
                }}
            >
                <Form.Item
                    label="服务名称"
                    name="service_name"
                    rules={[
                        {
                            required: true,
                            message: '请选择服务名称!'
                        }
                    ]}>
                    <Select placeholder="请选择服务名称">
                        <Select.Option value="21">FedEx Ground®</Select.Option>
                        <Select.Option value="24">FedEx Home Delivery®  (FedEx Ground Service)</Select.Option>
                        <Select.Option value="25">FEDEX GE</Select.Option>
                        <Select.Option value="26">FEDEX GE+</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label="启用时间"
                    name="eff_time"
                    rules={[
                        {
                            required: true,
                            message: '请选择启用时间!'
                        }
                    ]}
                    >
                    <DatePicker 
                        disabledDate={disableAllDayExceptMonday}
                        />
                </Form.Item>
                <Form.Item
                    label="费率"
                    name="rate"
                    rules={[
                        {
                            required: true,
                            message: '请填写费率!'
                        }, {
                            validator: twoDecimalPlacesOnly,
                            message: '仅支持两位小数'
                        }
                    ]}>
                    <Input suffix="%"/>
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default FuelSurchargeAddModal;