import React, { useState, useEffect } from 'react';
import { Table, Space, Button, notification, Modal, Dropdown } from 'antd';
import { FixedTrackingColumns } from "./ErrorHandlingTabColumns";
import { ProTable } from '@ant-design/pro-components';
import {
    useDisputeBillingDetailsMutation,
    useUpdateUidMutation, useBatchUpdateUidMutation,
    useSunkCostMutation, useGetTrackingMasterTrackingMutation,
} from '../../features/api/apiSlice';
import UidEditModal from "../billingDetails/UidEditModal";
import TrackingMasterUIDModal from "./TrackingMasterUIDModal";
function UIDErrorTab({ data, loading, searchData, setSearchData, currentPage, setCurrentPage, pageSize, setPageSize, UIDData }) {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [uidEditModalVisible, setUidEditModalVisible] = useState(false);
    const [openSurcharge, setOpenSurcharge] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [trackingMasterRefOpen, setTrackingMasterRefOpen] = useState(false);
    const [updateUid, {
        isSuccess: isUpdateUidSuccess,
        isError: isUpdateUidError,
        error: updateUidError,
        isLoading: isUpdateUidLoading,
        isFetching: isUpdateUidFetching
    }] = useUpdateUidMutation();
    const [disputeBillingDetails, {
        isLoading: disputeLoading,
        isSuccess: isDisputeSuccess,
        isError: isDisputeError,
        error: disputeError,
    }] = useDisputeBillingDetailsMutation();
    const [sunkCostHandler, {
        isLoading: sunkCostLoading,
        isSuccess: isSunkCostSuccess,
        isError: isSunkCostError,
        error: sunkCostError,
    }] = useSunkCostMutation();
    const [TrackingMasterTracking, {
        data: trackingData,
        isLoading: trackingMasterTrackingLoading,
        isSuccess: isTrackingMasterTrackingSuccess,
        isError: isTrackingMasterTrackingError,
        error: trackingMasterTrackingError,
    }] = useGetTrackingMasterTrackingMutation();
    const [batchUpdateUid, {
        isSuccess: isBatchUpdateUidSuccess,
        isError: isBatchUpdateUidError,
        error: BatchUpdateUidError,
        isLoading: isBatchUpdateUidLoading,
        isFetching: isBatchUpdateUidFetching
    }] = useBatchUpdateUidMutation();
    useEffect(() => {
        if (isSunkCostSuccess) {
            notification.success({
                message: 'Success',
                description: '标记为Sunk Cost成功',
            })
            setSelectedRowKeys([]);
        }
        if (isSunkCostError) {
            notification.error({
                message: 'Error',
                description: sunkCostError?.data?.error ?? '标记为Sunk Cost失败',
            })
        }
    }, [isSunkCostSuccess, isSunkCostError, sunkCostError])
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [searchData])
    useEffect(() => {
        if (isDisputeSuccess) {
            notification.success({
                message: 'Success',
                description: 'Dispute success',
            })
        }
        if (isDisputeError) {
            notification.error({
                message: 'Error',
                description: disputeError?.data?.error ?? 'Dispute failed for unknown reason',
            })
        }
    }, [isDisputeSuccess, isDisputeError, disputeError])
    useEffect(() => {
        if (isUpdateUidSuccess) {
            notification.success({
                message: "Success",
                description: "修改成功",
            });
            setUidEditModalVisible(false);
            setSelectedRowKeys([]);
        }
        if (isUpdateUidError) {
            notification.error({
                message: "Error",
                description: updateUidError?.data?.error || "修改失败",
            });
        }
    }, [isUpdateUidSuccess, isUpdateUidError])
    useEffect(() => {
        if (isUpdateUidSuccess) {
            notification.success({
                message: "Success",
                description: "修改成功",
            });
            setUidEditModalVisible(false);
            setSelectedRowKeys([]);
        }
        if (isBatchUpdateUidError) {
            notification.error({
                message: "Error",
                description: BatchUpdateUidError?.data?.error || "修改失败",
            });
        }
    }, [isBatchUpdateUidSuccess, isBatchUpdateUidError])
    useEffect(() => {
        if (isTrackingMasterTrackingSuccess) {
            setSelectedRowKeys([]);
            if (Object.keys(trackingData.data).length > 0) {
                setTrackingMasterRefOpen(true);
            } else {
                notification.error({
                    message: 'Warning',
                    description: 'Failed to track and obtain the master tracking number!',
                })
            }
        }
        if (isTrackingMasterTrackingError) {
            notification.error({
                message: 'Error',
                description: trackingMasterTrackingError?.data?.error ?? 'Failed to track and obtain the master tracking number!',
            })
        }
    }, [isTrackingMasterTrackingSuccess, isTrackingMasterTrackingError, trackingMasterTrackingError])
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
        selectedRowKeys,
    };
    const disputeButtonHandler = () => {
        const keys = selectedRowKeys;
        if (keys.length === 0) {
            return notification.error({
                message: 'Error',
                description: '请选择至少一条记录',
            })
        }
        disputeBillingDetails({ ids: keys, dispute_number: 4 });
    }
    const uidEditButtonHandler = () => {
        if (selectedRowKeys.length === 0) {
            return notification.error({
                message: "Error",
                description: "请选择至少一条记录",
            });
        }
        const notUidRows = selectedRows.filter(row => row.status !== 'UID异常');
        if (notUidRows.length > 0) {
            return notification.error({
                message: "Error",
                description: "请选择UID异常的记录",
            });
        }
        setUidEditModalVisible(true);
    }
    const uidEditModalSubmitHandler = (values) => {
        const newUid = values?.uid;
        const formData = new FormData();
        formData.append('uid', newUid);
        formData.append('ids', selectedRowKeys);
        updateUid(formData);
    }
    const columns = FixedTrackingColumns(setOpenSurcharge, setModalContent);
    const surcharge_columns = [{
        title: '费用类型',
        dataIndex: 'type',
        key: 'type'
    }, {
        title: '金额',
        dataIndex: 'amount',
        key: 'amount'
    }];
    const renderModalTable = () => {
        return <Table columns={surcharge_columns} dataSource={modalContent} pagination={false} />;
    };
    const items = [
        {
            label: '标记为自用面单-Company',
            key: '1',
        },
        {
            label: '标记为自用面单-Personal',
            key: '2',
        },
    ];
    const handleMenuClick = (e) => {
        if (selectedRowKeys.length === 0) {
            return notification.error({
                message: 'Error',
                description: '请选择至少一条记录',
            })
        }
        if (e.key === '1') {
            const request = {
                ids: selectedRowKeys,
                to_uid: '0005',
                flag: true,
            };
            sunkCostHandler(request);
        }
        if (e.key === '2') {
            const request = {
                ids: selectedRowKeys,
                to_uid: '0007',
                flag: true,
            };
            sunkCostHandler(request);
        }
    }
    const menuProps = {
        items,
        onClick: handleMenuClick,
    }
    const trackingRefButtonHandler = () => {
        const keys = selectedRowKeys;
        if (keys.length === 0) {
            return notification.error({
                message: 'Error',
                description: '请选择至少一条记录',
            })
        }
        if (keys.length > 30) {
            return notification.error({
                message: 'Error',
                description: '系统不支持一次性追踪超过30条记录',
            })
        }
        TrackingMasterTracking({'ids': selectedRowKeys, "result_key": "reference"})
    }
    const onTrackingMasterUIDSubmit = (values) => {
        const ids = JSON.stringify({ ids: values })
        batchUpdateUid(ids);
    }
    return (
        <Space direction="vertical" style={{ display: 'flex'}}>
            <ProTable
                search={false}
                columns={columns}
                rowKey={record => record.id}
                loading={loading}
                dataSource={data?.results}
                rowSelection={rowSelection}
                pagination={{
                    showSizeChanger: true,
                    pageSize: pageSize,
                    current: currentPage,
                    total: data?.count,
                    onChange: (page, pageSize) => {
                        setCurrentPage(page);
                        setPageSize(pageSize);
                        setSelectedRowKeys([]);
                    },
                    onShowSizeChange: (current, size) => {
                        setCurrentPage(current);
                        setPageSize(size);
                        setSelectedRowKeys([]);
                    },
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                scroll={{ x: 'max-content' }}
                options={{
                    reload: false,
                }}
                toolbar={{
                    title: ' ',
                    search: false,
                    actions: [
                        <Button type="primary" onClick={trackingRefButtonHandler} loading={trackingMasterTrackingLoading}>追踪UID</Button>,
                        <Button type="primary" onClick={uidEditButtonHandler}>修改UID</Button>,
                        <Dropdown menu={menuProps}>
                            <Button type="primary" loading={sunkCostLoading}>标记</Button>
                        </Dropdown>,
                        <Button loading={disputeLoading} onClick={disputeButtonHandler} >Dispute</Button>,
                    ],
                }}
            />
            <TrackingMasterUIDModal
                loadData={trackingData}
                data={data}
                open={trackingMasterRefOpen}
                setOpen={setTrackingMasterRefOpen}
                loading={isBatchUpdateUidFetching || isBatchUpdateUidLoading}
                onSubmit={onTrackingMasterUIDSubmit}
                success={isBatchUpdateUidSuccess}
            />
            <UidEditModal
                open={uidEditModalVisible}
                setOpen={setUidEditModalVisible}
                onSubmit={uidEditModalSubmitHandler}
                loading={isUpdateUidFetching || isUpdateUidLoading}
                success={isUpdateUidSuccess}
            />
            <Modal
                title="附加费详情"
                open={openSurcharge}
                onOk={() => setOpenSurcharge(false)}
                onCancel={() => setOpenSurcharge(false)}
                width={800}
            >
                {renderModalTable()}
            </Modal>
        </Space>
    )
}

export default UIDErrorTab;