import React, { useState, useRef, useEffect } from 'react';
import { Modal, Form, Input, Select, notification } from 'antd';
import { useCreateClientMutation } from '../../features/api/apiSlice';
function ClientCreateModal ({ open, setOpen, rateTemplates, userData }) {
    const [customerServiceHidden, setCustomerServiceHidden] = useState(true);
    const [form] = Form.useForm();
    const uidInputRef = useRef();
    const [createClient, { isError, isLoading, error, isSuccess}] = useCreateClientMutation();
    const handleCancel = () => {
        setOpen(false);
    }
    useEffect(() => {
        if (isError) {
            if (error.data && 'uid' in error.data) {
                notification.error({
                    message: "Error",
                    description: "客户ID已存在",
                });
            } else {
                notification.error({
                    message: "Error",
                    description: "Unknown error",
                });
            }
            uidInputRef.current.select();
        }
    }, [isError])
    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: "Success",
                description: "客户创建成功",
            });
            setOpen(false);
            form.resetFields();
        }
    }, [isSuccess])
    const handleOk = () => {
        form.validateFields().then(values => {
            const data = {
                uid: values.uid,
                username: values.username,
                user: values.userAssignedTo,
                contact: values.contact,
                rate_template: values.rateTemplate,
                comments1: values.comments1 === 'other' ? values.comments1Other : values.comments1,
                user_group: values.user_group,
            }
            createClient(data);
        }).catch((info) => {
            console.log("Validate Failed:", info);
        });
    }
    const NumberOnly = (rule, value, callback) => {
        return new Promise((resolve, reject) => {
            if (value && !/^[a-zA-Z0-9]+$/.test(value)) {
              reject('Please input combination of numbers and characters only!');
            } else {
              resolve();
            }
          });
    }
    const handleFormValuesChange = (changedValues, allValues) => {
        if (changedValues.hasOwnProperty('comments1')) {
            if (changedValues.comments1 === 'other') {
                setCustomerServiceHidden(false);
                form.setFieldsValue({ comments1Other: undefined });
            } else {
                setCustomerServiceHidden(true);
                form.setFieldsValue({ comments1Other: undefined });
            }
        }
      };
    return (
        <Modal title="添加新客户" open={open} onOk={handleOk} onCancel={handleCancel} confirmLoading={isLoading}>
            <Form
                form={form}
                onValuesChange={handleFormValuesChange}
                labelCol={{
                    span: 5,
                }}
                wrapperCol={{
                    span: 19,
                }}
                style={{ marginTop: '20px', maxWidth: '100%' }}>
                <Form.Item
                    name="uid"
                    label="客户ID"
                    rules={[
                        {
                            required: true,
                            message: '请填写客户ID!'
                        }, {
                            validator: NumberOnly,
                            message: '仅支持数字格式'
                        }
                    ]}
                    validateTrigger="onSubmit">
                    <Input ref={uidInputRef}/>
                </Form.Item>
                <Form.Item
                    name="rateTemplate"
                    label="客户分类">
                    <Select allowClear>
                        {rateTemplates && rateTemplates.map((rateTemplate) => (
                            <Select.Option key={rateTemplate.id} value={rateTemplate.id}>
                                {rateTemplate.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="username" label="客户名" rules={[{ required: true, message: '请填写客户名!' }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="contact" label="客户对接人">
                    <Select allowClear>
                        {userData && userData.map(user => (
                            <Select.Option key={user.id} value={user.id}>
                                {user.username}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                <Form.Item name="userAssignedTo" label="账单负责人" rules={[{ required: true, message: '请选择账单负责人!' }]}>
                    <Select>
                        {userData && userData.map(user => (
                            <Select.Option key={user.id} value={user.id}>
                                {user.username}
                            </Select.Option>
                        ))}
                        </Select>
                </Form.Item>
                <Form.Item name="comments1" label="业务类型">
                    <Select allowClear>
                        <Select.Option value="API">API</Select.Option>
                        <Select.Option value="Prelabeled">Prelabeled</Select.Option>
                        <Select.Option value="易仓一件代发">易仓一件代发</Select.Option>
                        <Select.Option value="other">other</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item name="comments1Other" label="" wrapperCol={{ span: 19, offset: 5 }} hidden={customerServiceHidden}>
                    <Input placeholder="请输入业务类型" />
                </Form.Item>
                <Form.Item name="user_group" label="客户分组" rules={[{ required: true, message: '请选择客户分组'}]}>
                    <Select>
                        <Select.Option value="1">Awesung</Select.Option>
                        <Select.Option value="2">Uparcel</Select.Option>
                    </Select>
                </Form.Item>            
            </Form>
        </Modal>
    )
}

export default ClientCreateModal;