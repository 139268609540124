import React, {useState, useEffect, useRef} from 'react';
import { Modal, Form, Select, notification, Input, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useGetQuoteSheetMutation } from '../../features/api/apiSlice';
import DisplayQuoteSheetModal from './DisplayQuoteSheetModal';
import axios from 'axios';
import UploadQuoteSheetErrorModal from "./UploadQuoteSheetErrorModal";
function UploadQuoteSheetModal({ open, setOpen, rateTemplates, logisticsSupplier, logisticsService, userData }) {
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [DisplayQuoteSheet, setDisplayQuoteSheet] = useState();
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [filteredServices, setFilteredServices] = useState([]);
    const [DisplayQuoteSheetModalOpen, setDisplayQuoteSheetModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [rateExcelInfo, setRateExcelInfo] = useState({});
    const handleCancel = () => {
        setOpen(false);
    }
    const modalRef = useRef();

    useEffect(() => {
        if (selectedSupplier && logisticsService) {
            setFilteredServices(logisticsService.filter(service => service.supplier.id === selectedSupplier));
        } else {
            setFilteredServices(logisticsService);
        }
    }, [selectedSupplier, logisticsService]);
    useEffect(() => {
        if (DisplayQuoteSheet) {
            setDisplayQuoteSheetModalOpen(true)
        }
    }, [DisplayQuoteSheet]);

    const onFinish = () => {
        setLoading(true);
        form.validateFields().then((values) => {
            const formData = new FormData();
            formData.append('rate_template', values.rateTemplate);
            formData.append('logistics_supplier', values.logisticsSupplier);
            formData.append('logistics_service', values.logisticsService);
            formData.append('year', values.year);
            formData.append('season', values.season);
            formData.append('note', values.note);
            formData.append('rateExcel', fileList[0]);
            
            axios.post('/accounts/upload-quote-sheet/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'credentials': 'include',
                }
            }).then(res => {
                setDisplayQuoteSheet(res.data);
                setRateExcelInfo({
                    rate_template: values.rateTemplate,
                    logistics_supplier: values.logisticsSupplier,
                    logistics_service: values.logisticsService,
                    year: values.year,
                    season: values.season,
                    note: values.note,
                    rateExcel: fileList[0],
                })

                setOpen(false);
            }).catch(err => {
                console.log(err);
                if (err.response?.status === 400) {
                    if (Array.isArray(err.response?.data?.error)){
                        modalRef.current.showError(err.response?.data?.error);
                    }else {
                        return notification.error({
                            message: '上传失败',
                            description: err.response?.data?.error,
                        })
                    }
                }
            }).finally(() => {
                setLoading(false);
            });
        }).catch(err => {
            console.log(err);
        })
    };
    const uploadProps = {
        onRemove: file => {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
        },
        beforeUpload: file => {
            setFileList([file]);
            return false;
        },
        fileList,
    }
    return (
        <>
            <Modal
                open={open}
                onCancel={handleCancel}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        取消
                    </Button>,
                    <Button key="submit" type="primary" htmlType="submit" loading={loading} onClick={() => form.submit()}>
                        保存信息并上传Excel
                    </Button>
                ]}
            >
                <Form
                    form={form}
                    onFinish={onFinish}
                    labelCol={{
                        span: 5,
                    }}
                    wrapperCol={{
                        span: 19,
                    }}
                    style={{ marginTop: '20px', maxWidth: '100%' }}>
                    <Form.Item
                        name="rateTemplate"
                        label="客户分类"
                        rules={[{
                            required: true,
                            message: "请填写客户分类!",
                        }]}
                        validateTrigger="onSubmit">
                        <Select>
                            {rateTemplates && rateTemplates.map((rateTemplate) => (
                                <Select.Option key={rateTemplate.id} value={rateTemplate.id}>{rateTemplate.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="logisticsSupplier"
                        label="物流商"
                        rules={[{
                            required: true,
                            message: "请填写物流商!",
                        }]}
                        validateTrigger="onSubmit">
                        <Select onChange={value => {setSelectedSupplier(value); form.setFieldValue('logisticsService',undefined);}}>
                            {logisticsSupplier && logisticsSupplier.map((logisticsSupplier) => (
                                <Select.Option key={logisticsSupplier.id} value={logisticsSupplier.id}>{logisticsSupplier.name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="year"
                        label="年份"
                        rules={[{
                            required: true,
                            message: "请填写年份!",
                        }]}
                        validateTrigger="onSubmit">
                        <Select>
                            {[1, 0, -1].map((offset) => {
                                const year = new Date().getFullYear() + offset;
                                return (
                                    <Select.Option key={year} value={year}>
                                        {year}
                                    </Select.Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="season"
                        label="季度"
                        rules={[{
                            required: true,
                            message: "请填写季度!",
                        }]}
                        validateTrigger="onSubmit">
                        <Select>
                            <Select.Option value="Q1">Q1</Select.Option>
                            <Select.Option value="Q2">Q2</Select.Option>
                            <Select.Option value="Q3">Q3</Select.Option>
                            <Select.Option value="Q4">Q4</Select.Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="note"
                        label="备注">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="rateExcel"
                        label="上传文件"
                        rules={[{
                            required: true,
                            message: "请上传文件!",
                        }]}
                        validateTrigger="onSubmit">
                        <Upload {...uploadProps}>
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>
                </Form>
            </Modal>
            {DisplayQuoteSheetModalOpen &&
                <DisplayQuoteSheetModal
                    open={DisplayQuoteSheetModalOpen}
                    setOpen={setDisplayQuoteSheetModalOpen}
                    data={DisplayQuoteSheet}
                    info={rateExcelInfo}
                    resetUploadForm={() => form.resetFields()}
                    resetFileList={() => setFileList([])}
                />}
            <UploadQuoteSheetErrorModal ref={modalRef} />
        </>
    )
}

export default UploadQuoteSheetModal;