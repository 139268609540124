import React, { useState, useEffect } from 'react';
import { Space, Tabs, } from 'antd';
import ErrorHandlingSearchBar from './ErrorHandlingSearchBar';
import MasterTrackingMismatchTab from './MasterTrackingMismatchTab';
import DuplicateTrackingsTab from './DuplicateTrackingsTab';
import ShipdateErrorTab from './ShipdateErrorTab';
import SurchargeErrorTab from './SurchargeErrorTab';
import {
    useGetAllCustomerUidQuery,
    useGetBillingInvoiceNamesQuery,
    useGetBillingDetailsWithErrorQuery,
    useGetLogisticsServiceByNameQuery,
} from '../../features/api/apiSlice';
import UIDErrorTab from "./UIDErrorTab";
function ErrorHandlingPage() {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchData, setSearchData] = useState({});
    const { data: customerData, refetch: refetchUid } = useGetAllCustomerUidQuery();
    const { data: billingInvoiceNames } = useGetBillingInvoiceNamesQuery();
    const [currentErrorHandlingTab, setCurrentErrorHandlingTab] = useState('1');
    const { data: logisticsService } = useGetLogisticsServiceByNameQuery();
    const { data: UIDData } = useGetBillingDetailsWithErrorQuery({ ...searchData, pageSize: pageSize, currentPage: currentPage, currentTab: 1 });
    const { data, isLoading, isFetching, refetch } = useGetBillingDetailsWithErrorQuery({ ...searchData, pageSize: pageSize, currentPage: currentPage, currentTab: currentErrorHandlingTab });
    useEffect(() => {
        refetch();
        refetchUid();
    }, [])
    const tabItems = [
        {
            label: 'UID异常',
            key: '1',
            children: <UIDErrorTab
                data={data}
                loading={isLoading || isFetching}
                searchData={searchData}
                setSearchData={setSearchData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                UIDData={UIDData}
            />,
        },
        {
            label: '重复子Tracking',
            key: '3',
            children: <DuplicateTrackingsTab
                data={data}
                loading={isLoading || isFetching}
                searchData={searchData}
                setSearchData={setSearchData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                UIDData={UIDData}
            />,
        },
        {
            label: 'Shipdate异常',
            key: '4',
            children: <ShipdateErrorTab
                data={data}
                loading={isLoading || isFetching}
                searchData={searchData}
                setSearchData={setSearchData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                UIDData={UIDData}
            />,
        },
        {
            label: 'Surcharge异常',
            key: '5',
            children: <SurchargeErrorTab
                data={data}
                loading={isLoading || isFetching}
                searchData={searchData}
                setSearchData={setSearchData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                UIDData={UIDData}
            />,
        },
        {
            label: 'Master异常',
            key: '7',
            children: <MasterTrackingMismatchTab
                data={data}
                loading={isLoading || isFetching}
                searchData={searchData}
                setSearchData={setSearchData}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                pageSize={pageSize}
                setPageSize={setPageSize}
                UIDData={UIDData}
            />,
        },
    ]

    return (
        <div>
            <Space direction="vertical" style={{ display: 'flex' }}>
                <ErrorHandlingSearchBar
                    searchData={searchData}
                    setSearchData={setSearchData}
                    customerData={customerData}
                    billingInvoiceNames={billingInvoiceNames}
                    logisticsService={logisticsService}
                    setCurrentPage={setCurrentPage}
                    setPageSize={setPageSize}
                    currentErrorHandlingTab={currentErrorHandlingTab}
                />
                <Tabs
                    centered
                    defaultActiveKey="1"
                    type="card"
                    size="large"
                    items={tabItems}
                    onChange={(activeKey) => {
                        setCurrentErrorHandlingTab(activeKey);
                        setCurrentPage(1);
                        setPageSize(10);
                    }}
                />
            </Space>
        </div>
    );
}

export default ErrorHandlingPage;