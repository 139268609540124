import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Space, theme, Select, DatePicker, notification } from 'antd';
import moment from 'moment-timezone';
import axios from 'axios';
const { RangePicker } = DatePicker;
const NonOriginalBillingDetailsSearchBar = ({ setSearchData, setCurrentPage, setPageSize, billingInvoiceNames }) => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };
    const [downloading, setDownloading] = useState(false);
    const getFields = () => {
        const children = [];
        children.push(
            <Col span={12} key={1}>
                <Form.Item name="billingInvoice" label="导入批次编号">
                    <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        style={{ textAlign: "left", width: '100%' }}
                        filterOption={(input, option) =>
                            (option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }>
                        {billingInvoiceNames && billingInvoiceNames.map(billingInvoiceName => (
                            <Select.Option key={billingInvoiceName.id} value={billingInvoiceName.id} label={billingInvoiceName.name}>
                                {billingInvoiceName.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>

        );
        return children;
    };
    const downloadButtonHandler = () => {
        setDownloading(true);
        const searchData = form.getFieldsValue();
        // trim all values
        Object.keys(searchData).forEach(key => {
            if (typeof searchData[key] === 'string') {
                searchData[key] = searchData[key].trim();
            }
        });
        const searchParams = new URLSearchParams();
        const map = {
            "pageSize": "page_size",
            "currentPage": "curr_page",
            "billingInvoice": "billing_invoice",
        }
        Object.keys(searchData).forEach(key => {
            if (searchData[key]) {
                searchParams.append(map[key], searchData[key]);
            }
        });
        // add isDownload = true
        searchParams.append('isDownload', true);

        axios.get("/accounts/get-non-original-billing-details/", {
            params: searchParams,
            withCredentials: true
        }).then((response) => {
            if (response.status === 200) {
                notification.success({
                    message: 'Success',
                    description: '导出成功,请前往下载管理页面获取文件',
                });
                setDownloading(false);
            } else {
                notification.error({
                    message: 'Error',
                    description: '导出失败,请联系管理员',
                });
                setDownloading(false);
            }
        }).catch((error) => {
            notification.error({
                message: 'Error',
                description: '导出失败,请联系管理员',
            });
            setDownloading(false);
        })
    }
    const onFinish = (values) => {
        const searchData = { ...values };
        // trim all values
        Object.keys(searchData).forEach(key => {
            if (typeof searchData[key] === 'string') {
                searchData[key] = searchData[key].trim();
            }
        });
        setSearchData(searchData);
        setCurrentPage(1);
        setPageSize(10);
    };

    return (
        <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
            <Row gutter={24}>{getFields()}</Row>
            <div
                style={{
                    textAlign: 'right',
                }}
            >
                <Space size="small">
                    <Button type="primary" loading={downloading} onClick={downloadButtonHandler}>
                        下载数据
                    </Button>
                    <Button type="primary" htmlType="submit">
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            form.resetFields();
                        }}
                    >
                        Clear
                    </Button>
                </Space>
            </div>
        </Form>
    );
};

export default NonOriginalBillingDetailsSearchBar;