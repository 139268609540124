import React, { useState, useEffect } from 'react';
import { Space, Table, } from 'antd';
import { 
    useGetNonOriginalBillingDetailsQuery,
    useGetBillingInvoiceNamesQuery,
} from '../../features/api/apiSlice';
import { billingDetailsColumns } from './NonOriginalBillingDetailsTableItems';
import NonOriginalBillingDetailsSearchBar from './NonOriginalBillingDetailsSearchBar';
function NonOriginalBillingDetailsPage() {
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchData, setSearchData] = useState({});
    const { data: billingInvoiceNames } = useGetBillingInvoiceNamesQuery();
    const { data, isLoading, isFetching, refetch } = useGetNonOriginalBillingDetailsQuery({...searchData, pageSize: pageSize, currentPage: currentPage });
    useEffect(() => {
        refetch();
    }, [])
    return (
        <Space direction="vertical" style={{ width: '100%' }}>
        <NonOriginalBillingDetailsSearchBar
            billingInvoiceNames={billingInvoiceNames}
            setSearchData={setSearchData}
            setCurrentPage={setCurrentPage}
            setPageSize={setPageSize}
        />
        <Table
            size='small'
            rowKey={record => record.id}
            columns={billingDetailsColumns}
            dataSource={data?.results}
            loading={isLoading || isFetching}
            pagination={{
                showSizeChanger: true,
                pageSize: pageSize,
                current: currentPage,
                total: data?.count,
                onChange: (page, pageSize) => {
                    setCurrentPage(page);
                    setPageSize(pageSize);
                },
                onShowSizeChange: (current, size) => {
                    setCurrentPage(current);
                    setPageSize(size);
                },
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}
            scroll={{ x: billingDetailsColumns.length * 200 }}
        />
        </Space>
    );
}

export default NonOriginalBillingDetailsPage;