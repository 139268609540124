import React, { useEffect, useState } from 'react';
import { Button, Col, Form, notification, Row, Space, theme, Select, DatePicker } from 'antd';
import moment from 'moment-timezone';
import ExportUIDExcelModal from './ExportUIDExcelModal';
import SplitUserModal from './SplitUserModal';
const { RangePicker } = DatePicker;
const ClientSearchBar = ({ userData, customerData, billingInvoiceNames, setSearchData, setCurrentPage, setPageSize }) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const [exportUidModalOpen, setExportUidModalOpen] = useState(false);
  const [splitUserModalOpen, setSplitUserModalOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const formStyle = {
    maxWidth: 'none',
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
  };

  const getFields = () => {
    const children = [];
    children.push(
      <Col span={8} key={1}>
        <Form.Item name='billingInvoice' label='导入批次编号'>
                <Select
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    style={{ textAlign: "left" }}
                    filterOption={(input, option) =>
                        (option?.label ?? '')
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    }
                    mode='multiple'>
                    {billingInvoiceNames && billingInvoiceNames.map(billingInvoiceName => (
                <Select.Option key={billingInvoiceName.id} value={billingInvoiceName.id} label={billingInvoiceName.name}>
                {billingInvoiceName.name}
              </Select.Option>
            ))}
            </Select>
        </Form.Item>
      </Col>,
      <Col span={8} key={2}>
        <Form.Item name='user' label='负责人'>
          <Select allowClear style={{ textAlign: "left" }} mode="multiple">
            {userData && userData.map(user => (
              <Select.Option key={user.id} value={user.id}>
                {user.username}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>,
      <Col span={8} key={3}>
        <Form.Item name='created_at' label='创建时间'>
            <RangePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
        </Form.Item>
      </Col>,
      <Col span={8} key={4}>
        <Form.Item name='customer' label='客户ID'>
          <Select
            allowClear
            style={{ textAlign: "left" }}
            mode="multiple"
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option?.label ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }>
            {customerData && customerData.map(customer => (
              <Select.Option key={customer.id} value={customer.id} label={customer.uid}>
                {customer.uid}
              </Select.Option>
            ))}
            </Select>
        </Form.Item>
      </Col>,
      <Col span={8} key={5}>
        <Form.Item name='status' label='状态'>
          <Select allowClear style={{ textAlign: "left" }} mode="multiple">
            <Select.Option value="-1">未出账</Select.Option>
            <Select.Option value="0">出账异常</Select.Option>
            <Select.Option value="1">UID异常</Select.Option>
            <Select.Option value="2">已出账</Select.Option>
          </Select>
        </Form.Item>
      </Col>,
    );
    return children;
  };

  const exportUIDExcelButtonHandler = () => {
    const searchData = form.getFieldsValue();
    if (!(searchData?.billingInvoice || searchData?.created_at || (searchData?.customer &&
      searchData?.customer.length !== 0))) {
      return notification.error({
            message: 'Error',
            description: '请搜索栏选择导入批次编号或者创建时间或者客户ID',
            duration: 1,
        });
    }
    if (searchData.created_at) {
      searchData.createdAtAfter = moment(searchData.created_at[0].toDate()).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
      searchData.createdAtBefore = moment(searchData.created_at[1].toDate()).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
      delete searchData.created_at;
    }
    setModalData(searchData);
    setExportUidModalOpen(true);
  }

  const splitUserButtonHandler = () => {
    const searchData = form.getFieldsValue();
    //47-3201 48-3177
    if (searchData?.customer &&
      searchData?.customer.length !== 0 && (searchData.customer.includes(47) || searchData.customer.includes(48))){

        if (searchData.created_at) {
          searchData.createdAtAfter = moment(searchData.created_at[0].toDate()).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
          searchData.createdAtBefore = moment(searchData.created_at[1].toDate()).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
          delete searchData.created_at;
        }
        setModalData(searchData);
        setSplitUserModalOpen(true);
    }else{
        return notification.error({
            message: 'Error',
            description: '所有选中的记录UID必须是3201或3177!',
            duration: 1,
        });
    }

  }

  const onFinish = (values) => {
    const searchData = {...values};
    if (searchData.created_at) {
      searchData.createdAtAfter = moment(searchData.created_at[0].toDate()).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
      searchData.createdAtBefore = moment(searchData.created_at[1].toDate()).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
      delete searchData.created_at;
    }
    setSearchData(searchData);
    setCurrentPage(1);
    setPageSize(10);
  };
  return (
    <Form
      form={form}
      name="advanced_search"
      style={formStyle}
      onFinish={onFinish}
    >
      <Row gutter={24}>{getFields()}</Row>
      <div
        style={{
          textAlign: 'right',
        }}
      >
        <Space size="small">
          <Button onClick={exportUIDExcelButtonHandler}>导出批次数据</Button>
          <Button onClick={splitUserButtonHandler}>拆分用户</Button>
          <Button type="primary" htmlType="submit">
            Search
          </Button>
          <Button
            onClick={() => {
              form.resetFields();
            }}
          >
            Clear
          </Button>
        </Space>
      </div>
      <ExportUIDExcelModal
        open={exportUidModalOpen}
        setOpen={setExportUidModalOpen}
        modalData={modalData}
      />
      <SplitUserModal
        open={splitUserModalOpen}
        setOpen={setSplitUserModalOpen}
        modalData={modalData}
      />
    </Form>
  );
};

export default ClientSearchBar;