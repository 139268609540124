import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Select, Upload, Button, notification, Spin } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { useUploadBillingInvoiceMutation } from '../../features/api/apiSlice';
function CreateNewBatchModal({ open, setOpen, logisticsSuppliers }) {
    const [form] = Form.useForm();
    const [fileList, setFileList] = useState([]);
    const [uploadBillingInvoice, { error, isError, isSuccess, isLoading }] = useUploadBillingInvoiceMutation();
    const handleOk = () => {
        form.validateFields().then(values => {
            const formData = new FormData();
            formData.append('billing_invoice_name', values.billingInvoiceName);
            formData.append('logistics_supplier', values.logisticsSupplier);
            formData.append('note', values.note);
            formData.append('File', fileList[0])
            uploadBillingInvoice(formData);
        }).catch((info) => {
            console.log("Validate Failed:", info);
        });
    }
    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Success',
                description: '上传成功',
            })
            setOpen(false);
            setFileList([]);
            form.resetFields();
        }
        if (isError) {
            notification.error({
                message: 'Error',
                description: error.data?.error || '未知错误',
            })
        }
    }, [isSuccess, isError])
    const uploadProps = {
        onRemove: file => {
            setFileList([]);
        },
        beforeUpload: file => {
            setFileList([file]);
            return false;
        },
        fileList,
        accept: '.zip',
        showRemoveIcon: false,
    }
    return (

        <Modal
            open={open}
            title="新建导入批次"
            onOk={handleOk}
            onCancel={() => setOpen(false)}
            confirmLoading={isLoading}
        >
            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                    style={{ marginTop: '20px', maxWidth: '100%' }}
                >
                    <Form.Item
                        name="billingInvoiceName"
                        label="导入批次编号"
                        rules={[
                            {
                                required: true,
                                message: '请填写导入批次编号!'
                            }
                        ]}
                        validateTrigger="onSubmit">
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name='logisticsSupplier'
                        label="物流商"
                        rules={[
                            {
                                required: true,
                                message: '请选择物流商!'
                            }
                        ]}
                        validateTrigger="onSubmit">
                        <Select>
                            {logisticsSuppliers && logisticsSuppliers.map((logisticsSupplier) => (
                                <Select.Option key={logisticsSupplier.id} value={logisticsSupplier.id}>
                                    {logisticsSupplier.name}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="uploadFile"
                        label="上传文件"
                        rules={[
                            {
                                required: true,
                                message: '请上传文件!'
                            }
                        ]}
                        validateTrigger="onSubmit">
                        <Upload {...uploadProps}>
                            <Button icon={<UploadOutlined />}>Click to Upload</Button>
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        name="note"
                        label="备注"
                        rules={[
                            {
                                validator: (_, value) => {
                                    if (value && value.length > 500) {
                                        return Promise.reject('输入的字符数不能超过500!');
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            },
                        ]}>
                        <Input placeholder="Less than 500 characters" />
                    </Form.Item>
                </Form>
            </Spin>
        </Modal>

    )
}

export default CreateNewBatchModal;