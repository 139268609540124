import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Select, notification } from 'antd';
import { useUpdateLogisticsServiceMutation } from '../../features/api/apiSlice';
function LogisticsEditModal({ state, setState }) {
    const [updateLogisticsService, { isLoading, isError, error, isSuccess }] = useUpdateLogisticsServiceMutation();
    useEffect(() => {
        if (isError) {
             if (error.data && 'non_field_errors' in error.data) {
                notification.error({
                    message: "Error",
                    description:error.data.non_field_errors,
                });
                setState(prevState => ({
                    ...prevState,
                    open: false,
                }))
            } else {
                notification.error({
                    message: "Error",
                    description: "Unknown error",
                });
            }
        }
    }, [isError])

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: "Success",
                description: "修改成功",
            });
            setState(prevState => ({
                ...prevState,
                open: false,
            }))
        }
    }, [isSuccess])
    const handleCancel = () => {
        setState(prevState => ({
            ...prevState,
            open: false,
        }))
    }
    const handleOk = () => {
        const data = {
            id: state.id,
            is_active: state.status === '1' ? true : false,
            note: state.note,
            updated_at: state.updated_at,
        }
        updateLogisticsService(data);
    }
    return (
        <Modal
            title={`编辑物流服务${state.name}`}
            open={state.open}
            onOk={handleOk}
            onCancel={handleCancel}
            confirmLoading={isLoading}
            okText="保存"
            cancelText="取消"
        >
            <Form
                labelCol={{
                    span: 5,
                }}
                wrapperCol={{
                    span: 19,
                }}
                style={{
                    marginTop: '20px'
                }}>
                <Form.Item label="服务状态">
                    <Select
                        value={state.status}
                        onChange={(value) => {
                            setState(prevState => ({ ...prevState, status: value }))
                        }}>
                        <Select.Option value="1">启用中</Select.Option>
                        <Select.Option value="0">停用</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label="备注">
                    <Input
                        value={state.note}
                        onChange={(e) => {
                            const value = e.target.value;
                            setState(prevState => ({ ...prevState, note: value }))
                        }}
                    />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default LogisticsEditModal;