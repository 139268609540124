import React, { useEffect } from 'react';
import {
    useGetDupDetailsQuery
} from '../../features/api/apiSlice';
import { Modal, Spin, Table } from 'antd';
import { dupDetailsColumns } from './ErrorHandlingTabColumns';
function DuplicateTrackingDetailsModal({open, setOpen, modalData}) {
    const { data, isLoading, isFetching, refetch } = useGetDupDetailsQuery({ tracking_id: modalData?.tracking_id });
    useEffect(() => {
        refetch();
    }, [open])
    return (
        <Modal
            title="Duplicate Tracking Details"
            open={open}
            onCancel={() => setOpen(false)}
            footer={null}
            style={{ maxHeight: '10%' }}
            width={'100%'}
        >
            <Spin spinning={isLoading || isFetching}>
            <Table
                rowKey={record => record.id}
                columns={dupDetailsColumns}
                dataSource={data?.data}
                pagination={false}
                scroll={{ x: 'max-content' }}
            />
            </Spin>
        </Modal>
    )
}

export default DuplicateTrackingDetailsModal;