// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }

.last-row {
    background-color: #d5d4d4; /* 修改为你想要的颜色 */
}`, "",{"version":3,"sources":["webpack://./src/components/billingDetails/BillingDetailsPage.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;EACxB;;AAEF;IACI,yBAAyB,EAAE,cAAc;AAC7C","sourcesContent":[".ant-checkbox-group {\n    display: flex;\n    flex-direction: column;\n  }\n\n.last-row {\n    background-color: #d5d4d4; /* 修改为你想要的颜色 */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
