import React, { useEffect } from 'react';
import { Modal, Space, Descriptions, Typography } from 'antd';
const { Text } = Typography;
function FuncConfirmModal({ open, setOpen, onSubmit, loading, content, isFilter, isMaster }) {
    const handleOk = () => {
        onSubmit();
    }

    return (
        <Modal
            title="再次确认"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={handleOk}
            centered
            confirmLoading={loading}
        >
            <Space direction="vertical">
                {isFilter ? <Text>将对以下查询结果进行处理：</Text> : <Text>将对以下内容进行处理：</Text>}
                {isFilter ? (
                    <Descriptions column={1} layout="vertical" size="small">
                        {content.map((item, index) => {
                            return Object.entries(item).map(([key, value], index) => {
                                if (value) {
                                    return <Descriptions.Item>{key}: {value}</Descriptions.Item>
                                }
                            }
                            )
                        })}
                    </Descriptions>
                ) : (
                    // only show the first 20, if there are more than 20, use elipsis
                    <Space direction="horizontal">
                        <Text style={{ whiteSpace: 'nowrap' }}>
                            {isMaster ? "Master Tracking Number:" : "Tracking Number:"}
                        </Text>
                        <Text>
                            {isMaster ? content.slice(0,12).map(item => item.master_tracking_number).join(", ") : content.slice(0, 12).map(item => item.tracking_id).join(', ')}
                            {content.length > 12 ? "..." : ""}
                        </Text>
                    </Space>
                )}
            </Space>
        </Modal>
    );
}

export default FuncConfirmModal;