// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag-cell {
    border-left: 1px solid #d0d0d0;
    border-right: 1px solid #d0d0d0;
}

/* 确保第一列和最后一列的边框也显示 */
.ag-row {
    border-left: 1px solid #d0d0d0;
    border-right: 1px solid #d0d0d0;
}

/* 添加水平边框 */
.ag-row {
    border-top: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
}
.ag-header-cell {
    background-color: #92d050;
}

/*!* 为表头单元格添加居中样式 *!*/
/*.ag-header-cell-label {*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*}*/
`, "",{"version":3,"sources":["webpack://./src/components/quoteSheet/QuoteSheet.css"],"names":[],"mappings":"AAAA;IACI,8BAA8B;IAC9B,+BAA+B;AACnC;;AAEA,qBAAqB;AACrB;IACI,8BAA8B;IAC9B,+BAA+B;AACnC;;AAEA,WAAW;AACX;IACI,6BAA6B;IAC7B,gCAAgC;AACpC;AACA;IACI,yBAAyB;AAC7B;;AAEA,qBAAqB;AACrB,0BAA0B;AAC1B,qBAAqB;AACrB,2BAA2B;AAC3B,+BAA+B;AAC/B,IAAI","sourcesContent":[".ag-cell {\n    border-left: 1px solid #d0d0d0;\n    border-right: 1px solid #d0d0d0;\n}\n\n/* 确保第一列和最后一列的边框也显示 */\n.ag-row {\n    border-left: 1px solid #d0d0d0;\n    border-right: 1px solid #d0d0d0;\n}\n\n/* 添加水平边框 */\n.ag-row {\n    border-top: 1px solid #d0d0d0;\n    border-bottom: 1px solid #d0d0d0;\n}\n.ag-header-cell {\n    background-color: #92d050;\n}\n\n/*!* 为表头单元格添加居中样式 *!*/\n/*.ag-header-cell-label {*/\n/*    display: flex;*/\n/*    align-items: center;*/\n/*    justify-content: center;*/\n/*}*/\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
