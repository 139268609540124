import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ApiProvider } from '@reduxjs/toolkit/dist/query/react';
import { apiSlice } from './features/api/apiSlice';
import axios from 'axios';
const root = ReactDOM.createRoot(document.getElementById('root'));

// change the base URL to the backend server
axios.defaults.baseURL = process.env.REACT_APP_AXIOS_BASE_URL;
root.render(
  <React.StrictMode>
    <ApiProvider api={apiSlice}>
    <BrowserRouter>
        <App />
    </BrowserRouter>
    </ApiProvider>
  </React.StrictMode>
);

