import { Row, Col, Card, Statistic, DatePicker } from 'antd';

const { RangePicker } = DatePicker;
function HomePage(props) {

    return (
        <div>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
                <h2 style={{ textAlign: 'left', marginRight: '20px' }}>出账总览</h2>
                <div>
                    invoice date:
                    <RangePicker style={{ marginLeft: '20px' }} size="middle" />
                </div>
            </Row>
            <Row gutter={16} style={{ marginBottom: '20px' }}>
                <Col span={8}>
                    <Card
                        style={{ backgroundColor: '#FDEDF3' }}
                        title={<Statistic
                            style={{ textAlign: 'left', marginTop: '10px', marginBottom: '10px' }}
                            title="未出账主单数"
                            value={56140}
                        />}
                        bordered={true}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '10px' }}>子单数</span>
                            <Statistic value={412458} valueStyle={{ fontSize: '1rem' }} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '10px' }}>客户数</span>
                            <Statistic value={38} valueStyle={{ fontSize: '1rem' }} />
                        </div>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card
                        style={{ backgroundColor: '#FDF3CF' }}
                        title={<Statistic
                            style={{ textAlign: 'left', marginTop: '10px', marginBottom: '10px' }}
                            title="部分出账主单数"
                            value={12}
                        />}
                        bordered={true}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '10px' }}>子单数</span>
                            <Statistic value={3423} valueStyle={{ fontSize: '1rem' }} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '10px' }}>客户数</span>
                            <Statistic value={12458} valueStyle={{ fontSize: '1rem' }} />
                        </div>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card
                        style={{ backgroundColor: '#E4EEFC' }}
                        title={<Statistic
                            style={{ textAlign: 'left', marginTop: '10px', marginBottom: '10px' }}
                            title="出账异常主单数"
                            value={126}
                        />}
                        bordered={true}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '10px' }}>票数</span>
                            <Statistic value={3423} valueStyle={{ fontSize: '1rem' }} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '10px' }}>客户数</span>
                            <Statistic value={12458} valueStyle={{ fontSize: '1rem' }} />
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col span={8}>
                    <Card
                        style={{ backgroundColor: '#D8E5D1' }}
                        title={<Statistic
                            style={{ textAlign: 'left', marginTop: '10px', marginBottom: '10px' }}
                            title="已出账主单数"
                            value={32}
                        />}
                        bordered={true}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '10px' }}>子单数</span>
                            <Statistic value={3423} valueStyle={{ fontSize: '1rem' }} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '10px' }}>客户数</span>
                            <Statistic value={12458} valueStyle={{ fontSize: '1rem' }} />
                        </div>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card
                        style={{ backgroundColor: '#F9E8FE' }}
                        title={<Statistic
                            style={{ textAlign: 'left', marginTop: '10px', marginBottom: '10px' }}
                            title="待推送主单数"
                            value={60}
                        />}
                        bordered={true}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '10px' }}>子单数</span>
                            <Statistic value={3423} valueStyle={{ fontSize: '1rem' }} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '10px' }}>客户数</span>
                            <Statistic value={12458} valueStyle={{ fontSize: '1rem' }} />
                        </div>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card
                        style={{ backgroundColor: '#F4D8BA' }}
                        title={<Statistic
                            style={{ textAlign: 'left', marginTop: '10px', marginBottom: '10px' }}
                            title="待确认主单数"
                            value={32}
                        />}
                        bordered={true}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '10px' }}>票数</span>
                            <Statistic value={3423} valueStyle={{ fontSize: '1rem' }} />
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <span style={{ marginRight: '10px' }}>客户数</span>
                            <Statistic value={12458} valueStyle={{ fontSize: '1rem' }} />
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default HomePage;