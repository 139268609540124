import React from 'react';
import { Button, Col, Form, Input, Row, Space, theme, Select, DatePicker } from 'antd';
import moment from 'moment-timezone';
const { RangePicker } = DatePicker;
const ClientSearchBar = ({ userData, recordTypeData, setSearchData, setCurrentPage, setPageSize }) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const formStyle = {
    maxWidth: 'none',
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
  };
  const getFields = () => {
    const children = [];
    children.push(
      <Col span={12} key={1}>
        <Form.Item name='record_type' label='下载类型'>
          <Select allowClear style={{ textAlign: "left" }} mode="multiple">
            {recordTypeData && recordTypeData.map(record_type => (
                <Select.Option key={record_type.id} value={record_type.id}>
                  {record_type.description}
                </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>,
      <Col span={12} key={2}>
        <Form.Item name='user' label='提交人'>
          <Select allowClear style={{ textAlign: "left" }} mode="multiple">
            {userData && userData.map(user => (
              <Select.Option key={user.id} value={user.id}>
                {user.username}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>,
      <Col span={12} key={3}>
        <Form.Item name='created_at' label='提交时间'>
            <RangePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
        </Form.Item>
      </Col>,
      <Col span={12} key={4}>
        <Form.Item name='status' label='状态'>
          <Select allowClear style={{ textAlign: "left" }} mode="multiple">
            <Select.Option value="2">下载中</Select.Option>
            <Select.Option value="3">已完成</Select.Option>
            <Select.Option value="4">下载失败</Select.Option>
          </Select>
        </Form.Item>
      </Col>

    );
    return children;
  };
  const onFinish = (values) => {
    const searchData = {...values};
    if (searchData.created_at) {
      searchData.createdAtAfter = moment(searchData.created_at[0].toDate()).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
      searchData.createdAtBefore = moment(searchData.created_at[1].toDate()).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss');
      delete searchData.created_at;
    }
    setSearchData(searchData);
    setCurrentPage(1);
    setPageSize(10);
  };
  return (
    <Form
      form={form}
      name="advanced_search"
      style={formStyle}
      onFinish={onFinish}
    >
      <Row gutter={24}>{getFields()}</Row>
      <div
        style={{
          textAlign: 'right',
        }}
      >
        <Space size="small">
          <Button type="primary" htmlType="submit">
            Search
          </Button>
          <Button
            onClick={() => {
              form.resetFields();
            }}
          >
            Clear
          </Button>
        </Space>
      </div>
    </Form>
  );
};

export default ClientSearchBar;