import React, { useState, useRef, useEffect } from 'react';
import { Modal, Form, Input, Select, notification } from 'antd';
import { useCreateLogisticsServiceMutation } from '../../features/api/apiSlice';
function LogisticsCreateModal({ open, setOpen, logisticsSuppliers }) {
    const [newLogisticsSupplierHidden, setNewLogisticsSupplierHidden] = useState(true);
    const [form] = Form.useForm();
    const [createLogisticsService, { isError, error, isSuccess, isLoading }] = useCreateLogisticsServiceMutation();
    const handleCancel = () => {
        setOpen(false);
    }
    useEffect(() => {
        if (isError) {
            notification.error({
                message: '创建物流服务失败',
                description: error.data.error,
            });
        }
    }, [isError])
    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: '成功',
                description: '成功录入物流服务!',
            });
            setOpen(false);
            form.resetFields();
        }
    }, [isSuccess])
    const handleOk = () => {
        form.validateFields().then(values => {
            const data = {
                supplier: values.logisticsSupplier,
                new_supplier_name: values.newLogisticsSupplier,
                name: values.logisticsService,
                note: values.note,
            };
            createLogisticsService(data);
        }).catch((info) => {
            console.log("Validate Failed:", info);
        });
    }
    const handleFormValuesChange = (changedValues, allValues) => {
        if (changedValues.hasOwnProperty('logisticsSupplier')) {
            if (changedValues.logisticsSupplier === 'other') {
                setNewLogisticsSupplierHidden(false);
                form.setFieldsValue({ newLogisticsSupplier: undefined });
            } else {
                setNewLogisticsSupplierHidden(true);
                form.setFieldsValue({ newLogisticsSupplier: undefined });
            }
        }
    };

    return (
        <Modal
            title="新建物流服务"
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            okText="保存"
            cancelText="取消"
            confirmLoading={isLoading}
        >
            <Form
                form={form}
                onValuesChange={handleFormValuesChange}
                labelCol={{
                    span: 5,
                }}
                wrapperCol={{
                    span: 19,
                }}
                style={{ marginTop: '20px', maxWidth: '100%' }}>
                <Form.Item
                    name="logisticsSupplier"
                    label="物流商"
                    rules={[
                        {
                            required: true,
                            message: '请填写物流商!'
                        }
                    ]}
                    validateTrigger="onSubmit">
                    <Select>
                        {logisticsSuppliers && logisticsSuppliers.map((logisticsSupplier) => (
                            <Select.Option key={logisticsSupplier.id} value={logisticsSupplier.id}>
                                {logisticsSupplier.name}
                            </Select.Option>
                        ))}
                        <Select.Option key="other" value="other">添加新物流商</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item
                    label=""
                    name="newLogisticsSupplier"
                    wrapperCol={{ span: 19, offset: 5 }}
                    hidden={newLogisticsSupplierHidden}
                    rules={[
                        {
                            required: !newLogisticsSupplierHidden,
                            message: '请填写新物流商名称!'
                        }
                    ]}
                    validateTrigger="onSubmit">
                    <Input />
                </Form.Item>
                <Form.Item
                    label="物流渠道"
                    name="logisticsService"
                    rules={[
                        {
                            required: true,
                            message: '请填写物流渠道!'
                        }
                    ]}>
                    <Input />
                </Form.Item>
                <Form.Item
                    label="备注"
                    name="note"
                >
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default LogisticsCreateModal;