import React, { useState, useRef } from 'react';
import { Modal, Input, notification } from 'antd';
import axios from 'axios';
function ForgetPasswordModal (props) {
    const inputRef = useRef();
    const [email, setEmail] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const handleOk = () => {
        if (email === undefined || email === "") {
            inputRef.current.focus();
            return notification.error({
                message: "失败",
                description: "请输入邮箱"
            })
        }
        setLoading(true);
        setButtonDisabled(true);
        axios.post('/accounts/forget_password/', { email: email }).then((res) => {
            props.setVisible(false);
            setEmail(undefined);
            setLoading(false);
            setButtonDisabled(false);
            return notification.success({
                message: "成功",
                description: "密码重置链接已发送至您的邮箱"
            })
        }).catch((err) => {
            setLoading(false);
            setButtonDisabled(false);
            inputRef.current.focus();
            return notification.error({
                message: "失败",
                description: "不存在与该邮箱关联的用户，请检查邮箱输入是否正确"
            })
        });
    }
    const handleEnter = (event) => {
        if (event.key === 'Enter') {
            handleOk();
        }
    }

    return (
        <Modal
            open={props.visible}
            title={"忘记密码（请输入用户邮箱）"}
            okButtonProps={{ loading: loading, disabled: buttonDisabled}}
            onOk={handleOk}
            onCancel={() => props.setVisible(false)}
            >

            <Input
                ref={inputRef}
                autoFocus={true}
                style={{ width: "50%", marginTop: "10px" }} 
                value={email} 
                onChange={(event) => {
                    const value = event.target.value;
                    setEmail(value);
                }}
                onKeyDown={handleEnter}
                />

        </Modal>
    )
}

export default ForgetPasswordModal;