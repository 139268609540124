import React, { useState, useEffect } from 'react';
import {Space, Table, Button, notification, Modal, Spin} from 'antd';
import { 
    useGetGroundCalculationDetailsQuery,
    useGetAllCustomerUidQuery,
} from '../../features/api/apiSlice';
import {
    ProTable,
  } from '@ant-design/pro-components';
import {GroundBillingDetailsColumns, GroundShipmentColumns} from "./GroundCalculationPageColumns";
import GroundCalculationSearchBar from "./GroundCalculationSearchBar";
import axios from 'axios';
import { useSelector } from "react-redux";
function GroundCalculationPage () {
    const [searchData, setSearchData] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [sorter, setSorter] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const { data, error, isLoading, isFetching, refetch } = useGetGroundCalculationDetailsQuery({...searchData, pageSize: pageSize, currentPage: currentPage, sortField: sorter.field,
        sortOrder: sorter.order,}, { refetchOnMountOrArgChange: true });
    const { data: customerData, refetch: refetchUid } = useGetAllCustomerUidQuery();
    const [tableData, setTableData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [costContent, setCostContent] = useState(null);
    const [openSurcharge, setOpenSurcharge] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [openPkgLoading, setOpenPkgLoading] = useState(false);
    const [openPkg, setOpenPkg] = useState(false);
    const [detailsContent, setDetailsContent] = useState(null);
    const [detailsCurrentPage, setDetailsCurrentPage] = useState(1);
    const [detailsPageSize, setDetailsPageSize] = useState(10);
    const columnPermissions = useSelector(state => state.auth.user.column_permission);
    const [openCost, setOpenCost] = useState(false);
    useEffect(() => {
        refetch();
        refetchUid();
    }, [])
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [searchData])
    useEffect(() => {
        if (!searchData.isDownload && data) {
            setTableData(data);
        }
    }, [data, searchData.isDownload]);
    useEffect(() => {
        setDetailsCurrentPage(1);
    }, [detailsContent])
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
        },
        selectedRowKeys,
      };
    const getSelectedRowsData = () => {
        return tableData.results.filter(row => selectedRowKeys.includes(row.id));
    };
    const handleTableChange = (pagination, filters, sorter) => {
        setSorter(sorter);
      };
    const trackingClickHandler = (record) => {
        setOpenPkg(true);
        setOpenPkgLoading(true);
        setDetailsContent([]);
        axios.post('/accounts/get-ground-calculation-billing-details/', {master_tracking_number: record.master_tracking_number, shipment_number: record.shipment_number}).then(res => {
            setDetailsContent(res.data?.data?.billing_details);
            setOpenPkgLoading(false);
        }).catch(err => {
            notification.error({
                message: 'Unknown Error',
                description: err.response.data.detail
            });
            setOpenPkgLoading(false);
            setOpenPkg(false);
        })
    }
    const columns = GroundShipmentColumns(trackingClickHandler, setOpenCost, setCostContent);
    const filteredColumns = columns.filter(item => columnPermissions?.includes(item.permission))
    const surcharge_columns = [{
        title: '费用类型',
        dataIndex: 'type',
        key: 'type'
    }, {
        title: '金额',
        dataIndex: 'amount',
        key: 'amount'
    }];
    const renderModalTable = () => {
        return <Table columns={surcharge_columns} dataSource={modalContent} pagination={false} />;
    };
    
    const renderCostTable = () => {
        return <Table
            columns={surcharge_columns}
            dataSource={costContent}
            pagination={false}
            rowClassName={rowClassName}  // 应用自定义的行类名函数
        />;
    };
    const rowClassName = (record, index) => {
        // 检查当前行是否为最后一行
        return index === costContent.length - 1 ? 'last-row' : '';
    };
    const renderPkgTable = () => {
        const PkgColumns = GroundBillingDetailsColumns(setOpenSurcharge, setModalContent);
        const filteredPkgColumns = PkgColumns.filter(item => columnPermissions?.includes(item.permission))
        return <Table
            rowKey={record => record.id}
            columns={filteredPkgColumns}
            dataSource={detailsContent}
            scroll={{ x: 'max-content' }}
            pagination={{
                showSizeChanger: true,
                pageSize: detailsPageSize,
                current: detailsCurrentPage,
                total: detailsContent?.count,
                onChange: (page, pageSize) => {
                    setDetailsCurrentPage(page);
                    setDetailsPageSize(pageSize);
                },
                onShowSizeChange: (current, size) => {
                    setDetailsCurrentPage(current);
                    setDetailsPageSize(size);
                },
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            }}

        />
    };
    return (
        <div>
            <Space
                direction="vertical"
                style={{ display: 'flex'}}>
                <GroundCalculationSearchBar
                    setSearchData={setSearchData}
                    customerData={customerData}
                    setCurrentPage={setCurrentPage}
                    setPageSize={setPageSize}
                    selectedRows={tableData && tableData.results ? getSelectedRowsData() : []}
                    selectedRowKeys={selectedRowKeys}
                    setSelectedRowKeys={setSelectedRowKeys}
                />
                <ProTable
                    search={false}
                    rowKey={record => record.id}
                    rowSelection={rowSelection}
                    columns={filteredColumns}
                    dataSource={tableData?.results}
                    loading={isLoading || isFetching}
                    onChange={handleTableChange}
                    pagination={{
                        showSizeChanger: true,
                        pageSize: pageSize,
                        current: currentPage,
                        total: tableData?.count,
                        onChange: (page, pageSize) => {
                            setCurrentPage(page);
                            setPageSize(pageSize);
                            setSelectedRowKeys([]);
                        },
                        onShowSizeChange: (current, size) => {
                            setCurrentPage(current);
                            setPageSize(size);
                            setSelectedRowKeys([]);
                        },
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    scroll={{ x: 'max-content' }}
                    options={{
                        reload: () => refetch(),
                    }}
                />
            </Space>
            <Modal
                title="附加费详情"
                open={openSurcharge}
                onOk={() => setOpenSurcharge(false)}
                onCancel={() => setOpenSurcharge(false)}
                width={800}
            >
                {renderModalTable()}
            </Modal>
            <Modal
                title="Details详情"
                open={openPkg}
                onCancel={() => setOpenPkg(false)}
                footer={null}
                style={{ maxHeight: '10%' }}
                width={'100%'}
            >
                <Spin spinning={openPkgLoading}>
                    {renderPkgTable()}
                </Spin>
            </Modal>
            <Modal
                title="费用详情"
                open={openCost}
                onOk={() => setOpenCost(false)}
                onCancel={() => setOpenCost(false)}
                width={800}
            >
                {renderCostTable()}
            </Modal>
        </div>
    )
}

export default GroundCalculationPage;