import React from 'react';
import { Modal, Table } from 'antd';

class UploadQuoteSheetErrorModal extends React.Component {
    state = {
        visible: false,
        data: [],
    };

    handleOk = () => {
        this.setState({ visible: false });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    showError = (errorData) => {
        if (Array.isArray(errorData)) {
            this.setState({ data: errorData, visible: true });
        }
    };

    render() {
        const { visible, data } = this.state;
        const columns = [
            {
                title: 'Error Message',
                dataIndex: 'message',
            },
        ];
        const dataSource = data.map((message, index) => ({ key: index, message }));

        return (
            <Modal
                title="Error Messages"
                open={visible}
                onOk={this.handleOk}
                onCancel={this.handleCancel}
            >
                <Table columns={columns} dataSource={dataSource} />
            </Modal>
        );
    }
}

export default UploadQuoteSheetErrorModal;
