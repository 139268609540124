import React, {useEffect, useState} from 'react';
import {Input, Form, Modal, Row, Col, Spin, Button} from 'antd';
import {PlusOutlined} from "@ant-design/icons";
function TrackingMasterTrackingModal ({loadData, data, open, setOpen, loading, onSubmit, success}) {
    // const [masterTrackingLoading, setMasterTrackingLoading] = useState(true);
    const [form] = Form.useForm();
    useEffect(() => {
        if (success) {
            form.resetFields();
            setOpen(false);
        }
    }, [success])
    useEffect(() => {
        if (loadData) {
            const formData = Object.entries(loadData.data).map(([current, newTrk], index) => ({
                key: index,
                current_track: current,
                new_master_trk: newTrk
            }));
            form.setFieldsValue({ tracks: formData });
            // setMasterTrackingLoading(false);
        }

    }, [loadData, form]);

    const handleOk = () => {
        form.validateFields().then(values => {
            // 创建一个存储更新后的ID和新轨迹的对象
            const updatedTracks = {};

            // 遍历表单中的所有轨迹数据
            values.tracks.forEach(track => {
                // 从data.results中找到匹配的tracking_id
                const foundItem = data.results.find(item => item.tracking_id === track.current_track);
                if (foundItem) {
                    // 如果找到了匹配项，将其id和新轨迹值加入到updatedTracks对象中
                    updatedTracks[foundItem.id] = track.new_master_trk;
                }
            });

            // 调用onSubmit，传递更新后的轨迹对象
            onSubmit(updatedTracks);
        }).catch(error => {
            console.log('Validate Failed:', error);
        });
    };


    return (
        <Modal
            title="追踪 Master Tracking"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={handleOk}
            centered
            confirmLoading={loading}
        >
            <Form
                form={form}
                style={{ marginTop: '20px', maxWidth: '100%' }}
                initialValues={{ tracks: [] }}
            >
                <Form.List name="tracks">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map(({ key, name }) => (
                                <Row gutter={24} key={key}>
                                    <Col span={12}>
                                        {/* 包含标签和超链接的 Form.Item */}
                                        <Form.Item
                                            label="子单号"
                                            shouldUpdate={(prevValues, currentValues) =>
                                                prevValues.tracks[name]?.current_track !== currentValues.tracks[name]?.current_track
                                            }
                                        >
                                            {({ getFieldValue }) => (
                                                <>
                                                    {/* 隐藏的 Input 用于表单数据收集 */}
                                                    <Input name={[name, 'current_track']} placeholder="请输入子单号" style={{ display: 'none' }} />

                                                    {/* 可点击的超链接 */}
                                                    <a
                                                        href={`https://www.fedex.com/wtrk/track/?action=track&trackingnumber=${getFieldValue(['tracks', name, 'current_track'])}&cntry_code=us&locale=en_us`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {getFieldValue(['tracks', name, 'current_track']) || "请输入子单号"}
                                                    </a>
                                                </>
                                            )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name={[name, 'new_master_trk']}
                                            label="主单号"
                                        >
                                            <Input placeholder="请输入主单号" />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            ))}
                        </>
                    )}
                </Form.List>
            </Form>
        </Modal>
    );
}


export default TrackingMasterTrackingModal;