import React from 'react';
import { Button, Col, Form, Input, Row, Space, theme, Select } from 'antd';

const LogisticsSearchBar = ({ logisticsSuppliers, setSearchData, setCurrentPage, setPageSize }) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const formStyle = {
    maxWidth: 'none',
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
  };
  const getFields = () => {
    const children = [];
    children.push(
      <Col span={12} key={1}>
        <Form.Item name='logisticsSupplier' label='物流商'>
          <Select placeholder='请选择物流商' allowClear style={{ textAlign: "left" }}>
            {logisticsSuppliers && logisticsSuppliers.map((logisticsSupplier) => (
              <Select.Option key={logisticsSupplier.id} value={logisticsSupplier.id}>
                {logisticsSupplier.name}
              </Select.Option>
            ))}
            </Select>
        </Form.Item>
      </Col>
    );
    return children;
  };
  const onFinish = (values) => {
    setSearchData(values);
    setCurrentPage(1);
    setPageSize(10);
  };
  return (
    <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
      <Row gutter={24}>{getFields()}</Row>
      <div
        style={{
          textAlign: 'right',
        }}
      >
        <Space size="small">
          <Button type="primary" htmlType="submit">
            Search
          </Button>
          <Button
            onClick={() => {
              form.resetFields();
            }}
          >
            Clear
          </Button>
        </Space>
      </div>
    </Form>
  );
};

export default LogisticsSearchBar;