import React, { useState, useEffect } from 'react';
import {Space, Table, Button, notification, Form} from 'antd';
import BillingBatchSearchBar from './BillingBatchSearchBar';
import {
    useGetAllCustomerUidQuery,
    useGetLogisticsServiceByNameQuery,
    useGetInvoiceQuery,
    useDownloadBillingBatchMutation,
    useReShipmentRateCalculationMutation,
    useGetBillingInvoiceNamesQuery,
} from '../../features/api/apiSlice';
import { renderDateTime } from '../utils';
function BillingBatchManagementPage () {
    const [searchData, setSearchData] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const { data, error, isLoading, isFetching, refetch } = useGetInvoiceQuery({currentPage: currentPage, pageSize: pageSize, ...searchData});
    const { data: logisticsService } = useGetLogisticsServiceByNameQuery();
    const { data: customerData, refetch: refetchUid } = useGetAllCustomerUidQuery();
    const { data: billingInvoiceNames } = useGetBillingInvoiceNamesQuery();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [downloadBillingBatch, {
        isSuccess: downloadBillingBatchSuccess,
        isLoading: downloadBillingBatchLoading,
        isError: downloadBillingBatchError,
        error: downloadBillingBatchErrorInfo,
    }] = useDownloadBillingBatchMutation();
    const [ReShipmentRateCalculation, {
        isSuccess: isReShipmentRateCalculationSuccess,
        isError: isReShipmentRateCalculationError,
        error: ReShipmentRateCalculationError,
        isLoading: isReShipmentRateCalculationLoading,
        isFetching: isReShipmentRateCalculationFetching,
    }] = useReShipmentRateCalculationMutation();
    useEffect(() => {
        if (isReShipmentRateCalculationSuccess) {
            return notification.success({
                message: "Success",
                description: "开始准备重新计算运费,请前往后台任务管理等待获取文件!",
            });
        }
        if (isReShipmentRateCalculationError) {
            return notification.error({
                message: "Error",
                description: ReShipmentRateCalculationError.data?.message,
            });
        }
    }, [isReShipmentRateCalculationSuccess, isReShipmentRateCalculationError]);
    useEffect(() => {
        refetch();
        refetchUid();
    }, [])
    useEffect(() => {
        setSelectedRowKeys([]);
    }, [searchData])
    useEffect(() => {
        if (downloadBillingBatchSuccess) {
            notification.success({
                message: '下载成功',
                description: '下载成功',
            });
        }
        if (downloadBillingBatchError) {
            notification.error({
                message: '下载失败',
                description: downloadBillingBatchErrorInfo?.data?.error || "未知原因,下载失败",
            });
        }
    }, [downloadBillingBatchSuccess, downloadBillingBatchError])
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
        },
        selectedRowKeys,
      };
    const columns = [
        {
            title: '出账批次',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: '客户ID',
            dataIndex: ['customer','uid'],
            key: 'uid',
        },
        {
            title: '包裹类型',
            dataIndex: 'package_type',
            key: 'package_type',
        },
        {
            title: '导入批次编号',
            dataIndex: 'billing_invoice_names',
            key: 'billing_invoice_names',
            render: (text, record) => {
                return <Space direction="vertical">
                    {record.billing_invoice_names.split("\n").map((name) => {
                        return <span key={name}>{name}</span>
                    })}
                </Space>
            }
        },
        {
            title: '物流服务',
            dataIndex: 'service_names',
            key: 'service_names',
            render: (text, record) => {
                return <Space direction="vertical">
                    {record.service_names.split(",").map((name) => {
                        return <span key={name}>{name}</span>
                    })}
                </Space>
            }
        },
        {
            title: '账单状态',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: '子单数',
            dataIndex: 'package_count',
            key: 'package_count',
        },
        {
            title: 'Net Charge Amount',
            dataIndex: 'net_charge_amount',
            key: 'net_charge_amount',
        },
        {
            title: 'Billed Amount',
            dataIndex: 'billed_amount',
            key: 'billed_amount',
        },
        {
            title: '账单负责人',
            dataIndex: ['user', 'username'],
            key: 'user_in_charge',
        },
        {
            title: '完成出账时间',
            dataIndex: 'bill_time',
            key: 'bill_time',
            render: renderDateTime,
        }
    ]
    const downloadBillingBatchHandler = () => {
        if (selectedRowKeys.length !== 1) {
            return notification.error({
                message: '请选择一条数据进行下载',
                description: '请选择一条数据进行下载',
            });
        }
        downloadBillingBatch({id: selectedRowKeys[0]})
    }
    const calculateShipmentHandler = () => {
        if (selectedRowKeys.length === 0) {
            return notification.error({
                message: "Error",
                description: "请选择至少一条记录",
            });
        }
        const formData = new FormData();
        formData.append('ids', selectedRowKeys)
        ReShipmentRateCalculation(formData)
    }
    return (
        <div>
            <Space
                direction="vertical"
                style={{ display: 'flex'}}>
            <BillingBatchSearchBar
                billingInvoiceNames={billingInvoiceNames}
                setSearchData={setSearchData}
                customerData={customerData}
                logisticsService={logisticsService}
                setCurrentPage={setCurrentPage}
                setPageSize={setPageSize}
            />
            <Space direction="horizontal" style={{ display: 'flex' }}>
                <Button type="primary" onClick={downloadBillingBatchHandler} loading={downloadBillingBatchLoading}>下载数据</Button>
                <Button type="primary" disabled={true} onClick={calculateShipmentHandler}
                        loading={isReShipmentRateCalculationLoading || isReShipmentRateCalculationFetching}>重新计算运费</Button>
            </Space>
            <Table
                size='small'
                rowKey={record => record.id}
                rowSelection={rowSelection}
                columns={columns}
                dataSource={data?.results}
                loading={isLoading || isFetching}
                pagination={{
                    showSizeChanger: true,
                    pageSize: pageSize,
                    current: currentPage,
                    total: data?.count,
                    onChange: (page, pageSize) => {
                        setCurrentPage(page);
                        setPageSize(pageSize);
                        setSelectedRowKeys([]);
                    },
                    onShowSizeChange: (current, size) => {
                        setCurrentPage(current);
                        setPageSize(size);
                        setSelectedRowKeys([]);
                    },
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                scroll={{x: columns.length * 200}}
            />
            </Space>
        </div>
    )
}

export default BillingBatchManagementPage;