import React, { useState } from 'react';
import { Modal, notification, Form, Input } from 'antd';
import axios from 'axios';
function DownloadModal ({open, setOpen, record, setRecord}) {
    const [form] = Form.useForm();
    const [downloadLoading, setDownloadLoading] = useState(false);
    const handleDownloadSubmit = () => {
        form.validateFields().then(values => {
            setDownloadLoading(true);
            const formData = new FormData();
            formData.append('id', record.id);
            axios.post('/accounts/download-async-management/', formData, {
                responseType: 'blob',
            }).then((res) => {
                try {
                    let fileName = values?.filename || 'download';
                    let type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                    if ([2, 3, 5].includes(record.record_type.id)) {
                        fileName = fileName + '.xlsx';
                    }
                    if ([1].includes(record.record_type.id)) {
                        fileName = fileName + '.zip';
                    }
                    const blob = new Blob([res.data], { type: type });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    // 提取文件名

                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    URL.revokeObjectURL(url);
                    setOpen(false);
                    setRecord(null);
                    form.resetFields();
                } catch (error) {
                    console.error('Download failed:', error);
                }
            }).catch((err) => {
                const err_msg = err.response?.data?.errors;
                notification.error({
                    message: 'Error',
                    description: err_msg,
                })
            }).finally(() => {
                setDownloadLoading(false);
            });
        }).catch(error => {
            console.log(error);
        })
    };
    return (
        <Modal
            title="下载"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={handleDownloadSubmit}
            centered
            confirmLoading={downloadLoading}
        >
            <Form
                form={form}
                labelCol={{
                    span: 10,
                }}
                wrapperCol={{
                    span: 14,
                }}
                style={{
                    marginTop: '20px',
                    marginLeft: '0%',
                    marginRight: '10%',
                    maxWidth: '90%'
                }}
            >
                <Form.Item
                    label="请输入自定义文件名"
                    name="filename"
                    rules={[{ required: true, message: '请输入自定义文件名!' }]}>
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default DownloadModal;