import { createSlice } from '@reduxjs/toolkit'
const initialState = {
    isAuthenticated: false,
    user: {},
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loadUser(state, action) {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      }
    },
    logoutUser(state, action) {
      return {
        ...state,
        isAuthenticated: false,
        user: {},
      }
    }
  },
})

export const getCurrentUser = (state) => state.auth.user
export const { loadUser, logoutUser } = authSlice.actions
export default authSlice.reducer