import React, { useState, useEffect } from 'react';
import { Modal, Select, notification } from 'antd';
import { useBulkUpdateClientStatusMutation } from '../../features/api/apiSlice';
function ClientStatusEditModal({open, setOpen, selectedRows, setSelectedRows, setSelectedRowKeys }) {
    const [status, setStatus] = useState(undefined);
    const [bulkUpdateClientStatus, { isLoading, isError, error, isSuccess }] = useBulkUpdateClientStatusMutation();
    
    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: "Success",
                description: "客户状态修改成功",
            })
            setOpen(false);
            setSelectedRows([]);
            setSelectedRowKeys([]);
        }
    }, [isSuccess])
    useEffect(() => {
        if (isError) {
            notification.error({
                message: "Error",
                description: error?.data.error ?? "Something went wrong",
            })
            setSelectedRows([]);
            setSelectedRowKeys([]);
        }
    }, [isError])
    const handleOk = () => {
        setOpen(false);
        const request = {
            updatedRows: selectedRows,
            status,
        }
        bulkUpdateClientStatus(request);

    }
    const handleCancel = () => {
        setOpen(false);
    }
    return (
        <Modal
            title={"修改客户状态"}
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            confirmLoading={isLoading}
            centered>
                <p>修改客户 {selectedRows ? selectedRows.map(row => `${row.username}${row.uid}`).join(",") : ""} 的状态为</p>
            <Select style={{ width: "50%" }} value={status} onChange={(value) => setStatus(value) }>
                <Select.Option value="Active">Active</Select.Option>
                <Select.Option value="Idle">Idle</Select.Option>
            </Select>
        </Modal>
    )
}
export default ClientStatusEditModal;