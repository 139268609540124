export const BaseRateData = (baseRates, service_name) => {
    if (service_name !== "FedEx Ground®(MWT)") {
        return Object.entries(baseRates["2"]).map(([weight, price], index) => {
            const rowData = { id: index, weight };
            for (let zone = 2; zone <= 9; zone++) {
                rowData[zone.toString()] = baseRates[zone.toString()][weight] || 'N/A';
            }
            return rowData;
        });
    } else {
        const rowData = [];
        let row = { id: 0, weight: '200lb' };
        for (let zone = 2; zone < 9; zone++) {
            row[zone.toString()] = baseRates[zone.toString()]['200lb'] || 'N/A';
        }
        rowData.push(row);
        row = { id: 1, weight: '500lb' };
        for (let zone = 2; zone < 9; zone++) {
            row[zone.toString()] = baseRates[zone.toString()]['500lb'] || 'N/A';
        }
        rowData.push(row);
        row = { id: 2, weight: 'min' };
        for (let zone = 2; zone < 9; zone++) {
            row[zone.toString()] = baseRates[zone.toString()]['minimum_package_rates'] || baseRates[zone.toString()]['min'] || 'N/A';
        }
        rowData.push(row);
        return rowData;
    }
};


export const AdditionRateData = (data, service_name) => {
    const transformed = [];
    const processEntry = (key, date, rates, id) => {
        const formattedRates = Object.fromEntries(
            Object.entries(rates).map(([zone, rate]) => [zone, typeof (rate) === "string" ? rate : parseFloat(rate).toFixed(2)])
        );
        return {
            id,
            description: key,
            date: date,
            ...formattedRates,
            charge_unit: '包裹'
        };
    };
    let id = 0;
    Object.entries(data).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            value.forEach(dateRange => {
                Object.entries(dateRange).forEach(([date, rates]) => {
                    transformed.push(processEntry(key, date, rates, id++));
                });
            });
        } else {
            Object.entries(value).forEach(([date, rates]) => {
                transformed.push(processEntry(key, date, rates, id++));
            });
        }
    });
    return transformed;
};