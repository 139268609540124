import { useSearchParams } from "react-router-dom";
import { Form, Input, Button, notification } from 'antd';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
function PasswordResetPage(props) {
    let [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const onFinish = (values) => {
        if (values.new_password1 !== values.new_password2) {
            return notification.error({
                message: 'Error',
                description: '两次输入的密码不一致',
            })
        }
        const formData = new FormData();
        formData.append('password', values.new_password1);
        formData.append('token', searchParams.get("token"));
        formData.append('id', searchParams.get("id"));
        axios.post('/accounts/reset_password/', formData).then((res) => {
            notification.success({
                message: '成功',
                description: '密码已重置',
            })
            navigate('/');
        }).catch((err) => {
            const err_msg = err.response?.data?.message;
            if (err_msg) {
                return notification.error({
                    message: 'Error',
                    description: err_msg,
                })
            }
        });

    }
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return (
        <>
            {/* {searchParams.get("token")} */}

            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    maxWidth: 600,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label="输入新密码"
                    name="new_password1"
                    rules={[
                        {
                            required: true,
                            message: '请输入新密码',
                        },
                    ]}
                >
                    <Input.Password autoComplete='on'/>
                </Form.Item>

                <Form.Item
                    label="再次输入新密码"
                    name="new_password2"
                    rules={[
                        {
                            required: true,
                            message: '请再次输入新密码',
                        },
                    ]}
                >
                    <Input.Password autoComplete='on' />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </>
    )
}
export default PasswordResetPage;