export const QuoteSheetTemplates = {
    "FEDEX GE+": {
        base_rate: [
            { field: 'weight', headerName: '重量段/LB', width: 50, flex: 3.6 },
            { field: '2', headerName: 'Zone2', width: 100, flex: 3.6, editable: true},
            { field: '3', headerName: 'Zone3', width: 100, flex: 3.6, editable: true},
            { field: '4', headerName: 'Zone4', width: 100, flex: 3.6, editable: true},
            { field: '5', headerName: 'Zone5', width: 100, flex: 3.6, editable: true},
            { field: '6', headerName: 'Zone6', width: 100, flex: 3.6, editable: true},
            { field: '7', headerName: 'Zone7', width: 100, flex: 3.6, editable: true},
            { field: '8', headerName: 'Zone8', width: 100, flex: 3.6, editable: true},
            { field: '9', headerName: 'Zone9', width: 100, flex: 3.6, editable: true}
        ],
        addition_rate: [
            { field: 'description', headerName: '附加费项目', flex: 15 },
            { field: 'date', headerName: '时间段', flex: 13, editable: true},
            { field: '2', headerName: 'Zone2', flex: 5, editable: true},
            { field: '3', headerName: 'Zone3', flex: 5, editable: true},
            { field: '4', headerName: 'Zone4', flex: 5, editable: true},
            { field: '5', headerName: 'Zone5', flex: 5, editable: true},
            { field: '6', headerName: 'Zone6', flex: 5, editable: true},
            { field: '7', headerName: 'Zone7', flex: 5, editable: true},
            { field: '8', headerName: 'Zone8', flex: 5, editable: true},
            { field: '9', headerName: 'Zone9', flex: 5, editable: true},
            { field: 'charge_unit', headerName: '收费单位', width: 60, flex: 5 },
        ]
    },
    "FEDEX GE": {
        base_rate: [
            { field: 'weight', headerName: '重量段/OZ', width: 50, flex: 3.5 },
            { field: '2', headerName: 'Zone2', width: 100, flex: 3.6, editable: true},
            { field: '3', headerName: 'Zone3', width: 100, flex: 3.6, editable: true},
            { field: '4', headerName: 'Zone4', width: 100, flex: 3.6, editable: true},
            { field: '5', headerName: 'Zone5', width: 100, flex: 3.6, editable: true},
            { field: '6', headerName: 'Zone6', width: 100, flex: 3.6, editable: true},
            { field: '7', headerName: 'Zone7', width: 100, flex: 3.6, editable: true},
            { field: '8', headerName: 'Zone8', width: 100, flex: 3.6, editable: true},
            { field: '9', headerName: 'Zone9', width: 100, flex: 3.6, editable: true}
        ],
        addition_rate: [
            { field: 'description', headerName: '附加费项目', flex: 15 },
            { field: 'date', headerName: '时间段', flex: 13, editable: true},
            { field: '2', headerName: 'Zone2', flex: 5, editable: true},
            { field: '3', headerName: 'Zone3', flex: 5, editable: true},
            { field: '4', headerName: 'Zone4', flex: 5, editable: true},
            { field: '5', headerName: 'Zone5', flex: 5, editable: true},
            { field: '6', headerName: 'Zone6', flex: 5, editable: true},
            { field: '7', headerName: 'Zone7', flex: 5, editable: true},
            { field: '8', headerName: 'Zone8', flex: 5, editable: true},
            { field: '9', headerName: 'Zone9', flex: 5, editable: true},
            { field: 'charge_unit', headerName: '收费单位', width: 60, flex: 5 },
        ]
    },
    "FedEx Ground®": {
        base_rate: [
            { field: 'weight', headerName: '重量段/LB', width: 50, flex: 3.5 },
            { field: '2', headerName: 'Zone2', width: 100, flex: 3.6, editable: true},
            { field: '3', headerName: 'Zone3', width: 100, flex: 3.6, editable: true},
            { field: '4', headerName: 'Zone4', width: 100, flex: 3.6, editable: true},
            { field: '5', headerName: 'Zone5', width: 100, flex: 3.6, editable: true},
            { field: '6', headerName: 'Zone6', width: 100, flex: 3.6, editable: true},
            { field: '7', headerName: 'Zone7', width: 100, flex: 3.6, editable: true},
            { field: '8', headerName: 'Zone8', width: 100, flex: 3.6, editable: true},
            { field: '9', headerName: 'Zone9', width: 100, flex: 3.6, editable: true}
        ],
        addition_rate: [
            { field: 'description', headerName: '附加费项目', flex: 15 },
            { field: 'date', headerName: '时间段', flex: 13, editable: true},
            { field: '2', headerName: 'Zone2', flex: 5, editable: true},
            { field: '3', headerName: 'Zone3', flex: 5, editable: true},
            { field: '4', headerName: 'Zone4', flex: 5, editable: true},
            { field: '5', headerName: 'Zone5', flex: 5, editable: true},
            { field: '6', headerName: 'Zone6', flex: 5, editable: true},
            { field: '7', headerName: 'Zone7', flex: 5, editable: true},
            { field: '8', headerName: 'Zone8', flex: 5, editable: true},
            { field: '9', headerName: 'Zone9', flex: 5, editable: true},
            { field: 'charge_unit', headerName: '收费单位', width: 60, flex: 5 },
        ]
    },
    "FedEx Home Delivery®  (FedEx Ground Service)": {
        base_rate: [
            { field: 'weight', headerName: '重量段/LB', width: 50, flex: 5 },
            { field: '2', headerName: 'Zone2', width: 100, flex: 3.6, editable: true},
            { field: '3', headerName: 'Zone3', width: 100, flex: 3.6, editable: true},
            { field: '4', headerName: 'Zone4', width: 100, flex: 3.6, editable: true},
            { field: '5', headerName: 'Zone5', width: 100, flex: 3.6, editable: true},
            { field: '6', headerName: 'Zone6', width: 100, flex: 3.6, editable: true},
            { field: '7', headerName: 'Zone7', width: 100, flex: 3.6, editable: true},
            { field: '8', headerName: 'Zone8', width: 100, flex: 3.6, editable: true},
            { field: '9', headerName: 'Zone9', width: 100, flex: 3.6, editable: true}
        ],
        addition_rate: [
            { field: 'description', headerName: '附加费项目', flex: 15 },
            { field: 'date', headerName: '时间段', flex: 13, editable: true},
            { field: '2', headerName: 'Zone2', flex: 5, editable: true},
            { field: '3', headerName: 'Zone3', flex: 5, editable: true},
            { field: '4', headerName: 'Zone4', flex: 5, editable: true},
            { field: '5', headerName: 'Zone5', flex: 5, editable: true},
            { field: '6', headerName: 'Zone6', flex: 5, editable: true},
            { field: '7', headerName: 'Zone7', flex: 5, editable: true},
            { field: '8', headerName: 'Zone8', flex: 5, editable: true},
            { field: '9', headerName: 'Zone9', flex: 5, editable: true},
            { field: 'charge_unit', headerName: '收费单位', width: 60, flex: 5 },
        ]
    },
    "FedEx Ground®(MWT)": {
        base_rate: [
            { field: 'weight', headerName: '重量段/LB', flex: 5 },
            { field: '2', headerName: 'Zone2', flex: 3, editable: true},
            { field: '3', headerName: 'Zone3', flex: 3, editable: true},
            { field: '4', headerName: 'Zone4', flex: 3, editable: true},
            { field: '5', headerName: 'Zone5', flex: 3, editable: true},
            { field: '6', headerName: 'Zone6', flex: 3, editable: true},
            { field: '7', headerName: 'Zone7', flex: 3, editable: true},
            { field: '8', headerName: 'Zone8', flex: 3, editable: true}
        ],
        addition_rate: [
            { field: 'description', headerName: '附加费项目', flex: 15 },
            { field: 'date', headerName: '时间段', flex: 13, editable: true},
            { field: '2', headerName: 'Zone2', flex: 5, editable: true},
            { field: '3', headerName: 'Zone3', flex: 5, editable: true},
            { field: '4', headerName: 'Zone4', flex: 5, editable: true},
            { field: '5', headerName: 'Zone5', flex: 5, editable: true},
            { field: '6', headerName: 'Zone6', flex: 5, editable: true},
            { field: '7', headerName: 'Zone7', flex: 5, editable: true},
            { field: '8', headerName: 'Zone8', flex: 5, editable: true},
            { field: 'charge_unit', headerName: '收费单位', width: 60, flex: 5 },
            { field: 'max', headerName: '封顶', flex: 5, editable: true}
        ]
    },

};

export function getTemplateForService(serviceType, actions) {
    const template = QuoteSheetTemplates[serviceType] || null;

    if (template && actions === 'preview') {
        // Loop through base_rate and addition_rate arrays and set editable to false
        template.base_rate.forEach(column => {
            if (column.hasOwnProperty('editable')) {
                column.editable = false;
            }
        });

        template.addition_rate.forEach(column => {
            if (column.hasOwnProperty('editable')) {
                column.editable = false;
            }
        });
    }

    return template;
}