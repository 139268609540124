import React, {useEffect, useState} from 'react';
import {Input, Form, Modal, Row, Col, Spin, Button, Select} from 'antd';
import {PlusOutlined} from "@ant-design/icons";

const { Option } = Select;
//{loadData, data, open, setOpen, loading, onSubmit, success}
function ModifyServiceModal ({selectedRowKeys, data, open, setOpen, loading, onSubmit, success}) {
    // const [masterTrackingLoading, setMasterTrackingLoading] = useState(true);
    const [form] = Form.useForm();
//    const selected_item = data.results.find(item => item.id === selectedRowKeys[0]);
    const selectedItem = data ? data.results.find(item => item.id === selectedRowKeys[0]): null;
    const trackingId = selectedItem ? selectedItem.tracking_id : null;
    useEffect(() => {
        if (success) {
            form.resetFields();
            setOpen(false);
        }
    }, [success])

    const handleOk = () => {
        form.validateFields().then(values => {
            onSubmit(values);
        }).catch(error => {
            console.log('Validate Failed:', error);
        });
    };


    return (
        <Modal
            title="修改Service"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={handleOk}
            centered
            confirmLoading={loading}
        >
            <Form
                form={form}
                style={{ marginTop: '20px', maxWidth: '100%' }}
            >
                <Col span={24}>
                    <Form.Item
                        label="Trk Number">
                                {/* 可点击的超链接 */}
                                <a
                                    href={`https://www.fedex.com/wtrk/track/?action=track&trackingnumber=${trackingId}&cntry_code=us&locale=en_us`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {trackingId}
                                </a>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name="Service"
                        label="Service"
                    >
                    <Select placeholder="请选择服务类型">
                      <Option value="FedEx Ground®">FedEx Ground®</Option>
                      <Option value="FedEx Home Delivery®  (FedEx Ground Service)">FedEx Home Delivery®  (FedEx Ground Service)</Option>
                    </Select>
                    </Form.Item>
                </Col>
            </Form>
        </Modal>
    );
}




export default ModifyServiceModal;