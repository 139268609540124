import React, {useEffect, useState} from 'react';
import {
    useGetAWSFileManagementQuery,
} from '../../features/api/apiSlice';
import {Button, notification, Space, Switch, Table} from 'antd';
function AWSFileManagementPage() {
    const [searchData, setSearchData] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [updateEnableLoading, setUpdateEnableLoading] = useState(false);
    const [rows, setRows] = useState({});
    const [sorter, setSorter] = useState({
        field: 'eff_time',
        order: 'descend'
    });
    const { data, error, isLoading, isFetching, refetch } = useGetAWSFileManagementQuery({...searchData, sorter, pageSize: pageSize, currentPage: currentPage});
    const columns = [
        {
            title: '文件名',
            dataIndex: 'file_name',
            key: 'file_name'
        },{
            title: "拉取时间",
            dataIndex: "created_at",
        },{
            title: "更新时间",
            dataIndex: "updated_at",
        },{
            title: "处理时间",
            dataIndex: "processed_at",
        },{
            title: '状态',
            dataIndex: 'status',
        },{
            title: "错误信息",
            dataIndex: 'error_message'
        }
    ];
    useEffect(() => {
        refetch();
    }, [])
    const handleTableChange = (pagination, filters, sorter) => {
        setPageSize(pagination.pageSize)
        setCurrentPage(pagination.current)
        // let a = {sorter['field']: sorter.ordered}
        setSorter(sorter)
        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== pageSize) {
            console.log('ABC')
        }
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, selectedRows) => {
            setSelectedRowKeys(newSelectedRowKeys);
        },
    };

    const getSelectedRowsData = () => {
        return data.results.filter(row => selectedRowKeys.includes(row.id));
    };

    useEffect(() => {
        if (selectedRowKeys.length >= 1) {
            setRows(getSelectedRowsData());
        }else {
            setRows({});
        }
    }, [selectedRowKeys])

    return (
        <div>
            <Space direction="vertical"
                size="middle"
                style={{
                    display: 'flex',
                }}>
                <Space direction="horizontal" style={{ display: 'flex' }}>
                    <Button onClick={refetch}>
                        刷新
                    </Button>
                </Space>
                <Table
                    rowSelection={rowSelection}
                    rowKey={(record) => record.id}
                    loading={isLoading || isFetching}
                    columns={columns}
                    dataSource={data}
                    onChange={handleTableChange}
                    pagination={{
                        showSizeChanger: true,
                        pageSize: pageSize,
                        current: currentPage,
                        total: data ? data.count : 0,
                        onChange: (page, pageSize) => {
                            setCurrentPage(page);
                            setPageSize(pageSize);
                        },
                        onShowSizeChange: (current, size) => {
                            setCurrentPage(current);
                            setPageSize(size);
                        },
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    scroll={{ x: 1500 }}
                />
            </Space>
        </div>
    )
}
export default AWSFileManagementPage;