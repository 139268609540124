import React from 'react';
import {Button, Col, DatePicker, Form, Row, Select, Space, theme} from 'antd';
import moment from 'moment-timezone';

const { RangePicker } = DatePicker;
const FuelSurchargeManagementSearchBar = ({ logisticsService, setSearchData, setCurrentPage, setPageSize }) => {
  const { token } = theme.useToken();
  const [form] = Form.useForm();
  const formStyle = {
    maxWidth: 'none',
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    padding: 24,
  };
  const getFields = () => {
    const children = [];
    children.push(
      <Col span={6} key={4}>
        <Form.Item name="logisticsService" label="物流服务">
          <Select placeholder='请选择' allowClear style={{textAlign: "left"}} mode='multiple'>
            {logisticsService && logisticsService.map((item) => (
                <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>,
      <Col span={6} key={3}>
        <Form.Item name='eff_time' label='启用时间'>
            <RangePicker style={{ width: '100%' }} format="YYYY-MM-DD" />
        </Form.Item>
      </Col>
    );
    return children;
  };

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const getMondaysInDateRange = (startDate, endDate) => {
    const mondays = [];
    let currentDate = new Date(startDate);
    currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1); // 设置到当周的周一
    while (currentDate <= endDate) {
      mondays.push(formatDate(currentDate)); // 添加周一到结果数组中
      currentDate.setDate(currentDate.getDate() + 7); // 下一周的周一
    }
    return mondays;
  }

  const onFinish = (values) => {
    const searchData = {...values};
    if (searchData.eff_time) {
      const effTimeAtAfter = moment(searchData.eff_time[0].toDate()).startOf('day').utc().format('YYYY-MM-DD');
      const effTimeAtBefore = moment(searchData.eff_time[1].toDate()).endOf('day').utc().format('YYYY-MM-DD');
      searchData.allMondays = getMondaysInDateRange(new Date(effTimeAtAfter), new Date(effTimeAtBefore))
      delete searchData.eff_time;
    }
    setSearchData(searchData);
    setCurrentPage(1);
    setPageSize(10);
  };
  return (
    <Form
      form={form}
      name="advanced_search"
      style={formStyle}
      onFinish={onFinish}
    >
      <Row gutter={24}>{getFields()}</Row>
      <div
        style={{
          textAlign: 'right',
        }}
      >
        <Space size="small">
          <Button type="primary" htmlType="submit">
            Search
          </Button>
          <Button
            onClick={() => {
              form.resetFields();
            }}
          >
            Clear
          </Button>
        </Space>
      </div>
    </Form>
  );
};

export default FuelSurchargeManagementSearchBar;