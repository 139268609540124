import React, { useEffect } from 'react';
import { Modal, Form, Input, notification } from 'antd';
import {
    useSplitUserChangeMutation,
} from '../../features/api/apiSlice';
function SplitUserModal({ open, setOpen, modalData }) {
    const [form] = Form.useForm();

    const [splitUser, {
        isSuccess: isSplitUserSuccess,
        isError: isSplitUserError,
        error: splitUserError,
        isLoading: isSplitUserLoading,
        isFetching: isSplitUserFetching
    }] = useSplitUserChangeMutation();

    useEffect(() => {
        if (isSplitUserSuccess) {
            notification.success({
                message: "Success",
                description: '导出成功,请前往下载管理页面获取文件',
            });
            setOpen(false);
        }
        if (isSplitUserError) {
            notification.error({
                message: "Error",
                description: splitUserError?.data?.error || "拆分失败",
            });
        }
    }, [isSplitUserSuccess, splitUserError])

    const handleOk = () => {

        const formData = new FormData();
        formData.append('searchData', JSON.stringify(modalData));
        splitUser(formData);

    }

    return (
        <Modal
            title="拆分用户"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={handleOk}
            centered
        >
            <p>是否进行拆分子用户</p>
        </Modal>
    );
}

export default SplitUserModal;
