import React from 'react';
import { Button, Col, Form, Input, Row, Space, theme, Select } from 'antd';

const QuoteSheetSearchBar = ({ customerData, logisticsSupplier, logisticsService, rateTemplates, setSearchData, setCurrentPage, setPageSize }) => {
    const { token } = theme.useToken();
    const [form] = Form.useForm();
    const formStyle = {
        maxWidth: 'none',
        background: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        padding: 24,
    };

    const getFields = () => {
        const children = [];
        children.push(
            <Col span={8} key={1}>
                <Form.Item name='user' label='客户ID'>
                    <Select
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label ?? '')
                                .toLowerCase()
                                .includes(input.toLowerCase())
                        }
                        style={{ textAlign: "left" }}
                    >
                        {customerData && customerData.map((customer) => (
                            <Select.Option key={customer.id} value={customer.id} label={customer.uid}>
                                {customer.uid}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>,
            <Col span={8} key={2}>
                <Form.Item name='logisticsSupplier' label='物流商'>
                    <Select allowClear style={{ textAlign: "left" }}>
                        {logisticsSupplier && logisticsSupplier.map((logisticsSupplier) => (
                            <Select.Option key={logisticsSupplier.id} value={logisticsSupplier.id}>
                                {logisticsSupplier.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>,
            <Col span={8} key={3}>
                <Form.Item name='year' label='年份'>
                    <Select
                        allowClear
                        style={{ textAlign: "left" }}
                        mode="multiple">
                        {[1, 0, -1].map((offset) => {
                            const year = new Date().getFullYear() + offset;
                            return (
                                <Select.Option key={year} value={year}>
                                    {year}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Form.Item>
            </Col>,
            <Col span={8} key={4}>
                <Form.Item name='rateTemplate' label='客户分类'>
                    <Select
                        allowClear
                        style={{ textAlign: "left" }}
                    >
                        {rateTemplates && rateTemplates.map((rateTemplate) => (
                            <Select.Option key={rateTemplate.id} value={rateTemplate.id}>
                                {rateTemplate.name}
                            </Select.Option>
                        ))}
                    </Select>
                </Form.Item>
            </Col>,
            <Col span={8} key={6}>
                <Form.Item name='season' label='季度'>
                    <Select
                        allowClear
                        style={{ textAlign: "left" }}
                        mode='multiple'
                    >
                        <Select.Option value="Q1">Q1</Select.Option>
                        <Select.Option value="Q2">Q2</Select.Option>
                        <Select.Option value="Q3">Q3</Select.Option>
                        <Select.Option value="Q4">Q4</Select.Option>
                    </Select>
                </Form.Item>
            </Col>,
        );
        return children;
    };
    const onFinish = (values) => {
        setSearchData(values);
        setCurrentPage(1);
        setPageSize(10);
    };
    return (
        <Form form={form} name="advanced_search" style={formStyle} onFinish={onFinish}>
            <Row gutter={24}>{getFields()}</Row>
            <div
                style={{
                    textAlign: 'right',
                }}
            >
                <Space size="small">
                    <Button type="primary" htmlType="submit">
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            form.resetFields();
                        }}
                    >
                        Clear
                    </Button>
                </Space>
            </div>
        </Form>
    );
};

export default QuoteSheetSearchBar;