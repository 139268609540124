import { useState, useEffect } from 'react';
import { Button, Checkbox, Form, Input, notification, Row, Card, Col } from 'antd';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import ForgetUsernameModal from './forgetUsernameModal';
import ForgetPasswordModal from './forgetPasswordModal';
import img from '../images/background.jpg';
import { useDispatch } from "react-redux";
import { loadUser } from "../features/auth/authSlice";
import { useSelector } from "react-redux";
import { logoutUser } from "../features/auth/authSlice";
const LoginPage = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const [forgetUsernameModalVisible, setForgetUsernameModalVisible] = useState(false);
    const [forgetPasswordModalVisible, setForgetPasswordModalVisible] = useState(false);
    const [counter, setCounter] = useState({});

    const auth = useSelector((store) => store.auth.isAuthenticated);
    const isLoggedIn = () => {
        if (auth) {
            navigate('/index/home');
        }
    }
    useEffect(() => {
        isLoggedIn();
      },[])
    useEffect(() => {
        if (localStorage.getItem('sessionTimeout') === 'true') {
            notification.error({
                message: 'Timeout',
                description: 'Your session has timed out. Please log in again.',
            });
            localStorage.removeItem('sessionTimeout');
            dispatch(logoutUser());
            navigate('/login');
        }
    }, []);
    const onFinish = (values) => {
        const formData = new FormData();
        formData.append('username', values.username);
        formData.append('password', values.password);
        axios.post('/accounts/login/', formData).then((res) => {
            dispatch(loadUser(res.data.user));
            navigate("/index/home");
        }).catch((err) => {
            const err_msg = err.response?.data?.errors;
            if (err_msg) {
                if ("username" in err_msg) {
                    return notification.error({
                        message: 'Error',
                        description: err_msg.username,
                    })
                } else if ("non_field_errors" in err_msg) {
                    let currentCounter = values.username in counter ? counter[values.username] + 1 : 1;
                    if (values.username in counter) {
                        setCounter({
                            ...counter,
                            [values.username]: counter[values.username] + 1
                        })
                    } else {
                        setCounter({
                            ...counter,
                            [values.username]: 1
                        })
                    }
                    if (currentCounter < 3) {
                        return notification.error({
                            message: 'Error',
                            description: err_msg.non_field_errors,
                        })
                    } else {
                        return notification.error({
                            message: 'Error',
                            description: '您已连续输错多次，是否需要找回密码？',
                        })
                    }
                    
                } else {
                    return notification.error({
                        message: 'Error',
                        description: 'Unknown Error',
                    })
                }
            } else {
                return notification.error({
                    message: 'Error',
                    description: 'Unknown Error',
                })
            }
        });
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const forgetUsernameButtonClickHandler = () => {
        setForgetUsernameModalVisible(true);
    }
    const forgetPasswordButtonClickHandler = () => {
        setForgetPasswordModalVisible(true);
    }
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundImage:`url(${img})`,    
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
        <Row type="flex" justify="center" align="middle">
            <Card title="登录" bordered={true}>
                <Form
                    name="basic"
                    labelCol={{
                        span: 8,
                    }}
                    wrapperCol={{
                        span: 16,
                    }}
                    style={{
                        maxWidth: 600,
                    }}
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Row gutter={16}>
                        <Col span={16}>
                            <Form.Item
                                label="Username"
                                name="username"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入用户名',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Button type="link" onClick={forgetUsernameButtonClickHandler}>Forget Username?</Button>
                        </Col>
                    </Row>
                    <Row gutter={16}>
                        <Col span={16}>
                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: '请输入密码',
                                    },
                                ]}
                            >
                                <Input.Password autoComplete='on' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Button type="link" onClick={forgetPasswordButtonClickHandler}>Forget Password?</Button>
                        </Col>
                    </Row>
                    <Form.Item
                        name="remember"
                        valuePropName="checked"
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16,
                        }}
                    >
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </Row>
        <ForgetUsernameModal 
            visible={forgetUsernameModalVisible}
            setVisible={setForgetUsernameModalVisible}
            />
        <ForgetPasswordModal
            visible={forgetPasswordModalVisible}
            setVisible={setForgetPasswordModalVisible}
            />
        </div>);
};
export default LoginPage;