import React, {useEffect, useState} from 'react';
import {
    useGetAsyncManagementQuery,
    useDeleteAsyncManagementMutation,
    useGetUserQuery,
    useGetRecordTypeQuery,
} from '../../features/api/apiSlice';
import {Button, notification, Space, Table} from 'antd';
import axios from "axios";
import AsyncManagementSearchBar from "./AsyncManagementSearchBar";
import DownloadModal from "./DownloadModal";
import { renderDateTime } from "../utils";
function AsyncManagementPage() {
    const [searchData, setSearchData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const { data, error, isLoading, isFetching, refetch } = useGetAsyncManagementQuery({...searchData, pageSize: pageSize, currentPage: currentPage});
    const { data: userData, error: userError, isLoading: userIsLoading, refetch: refetchUserData } = useGetUserQuery();
    const { data: recordTypeData, error: recordTypeError, isLoading: recordTypeIsLoading } = useGetRecordTypeQuery();
    
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [deleteLoading, setDeleteLoading] = useState(false);
    const [downloadModalVisible, setDownloadModalVisible] = useState(false);
    const [downloadRecord, setDownloadRecord] = useState(null);
    const [DeleteItem, {
        isSuccess: isDeleteItemSuccess,
        isError: isDeleteItemError,
        error: deleteItemError
    }] = useDeleteAsyncManagementMutation();
    useEffect(() => {
        refetch();
        refetchUserData();
    }, [])

    const handleDownload = (record) => {
        if ([1, 5].includes(record.record_type.id)) {
            const formData = new FormData();
            formData.append('id', record.id);
            axios.post('/accounts/download-async-management/', formData, {
                responseType: 'blob',
            }).then((res) => {
                try {
                    let fileName = record?.import_name || 'download';
                    if ([1].includes(record.record_type.id)) {
                        fileName = fileName + '.zip';
                    } else if ([5].includes(record.record_type.id)) {
                        fileName = fileName + '.xlsx';
                    } else {
                        return notification.error({
                            messgae: 'Error',
                            description: '未知原因,联系管理员',
                        })
                    }
                    let type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
                    const blob = new Blob([res.data], { type: type });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;
                    // 提取文件名

                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                    URL.revokeObjectURL(url);
                } catch (error) {
                    console.error('Download failed:', error);
                }
            }).catch((err) => {
                const err_msg = err.response?.data?.errors;
                notification.error({
                    message: 'Error',
                    description: err_msg,
                })
            }).finally(() => {
                setDownloadLoading(false);
            });
        } else {
            setDownloadModalVisible(true);
            setDownloadRecord(record);
        }
        
    }
    const renderDownloadButton = (record) => {
        // 假设状态字段名为 'status'
        const isDownloadable = record.status === '已完成' && record.record_type.id !== 9;

        return (
            <Button
                type="link"
                disabled={!isDownloadable}
                loading={downloadLoading}
                onClick={() => handleDownload(record)}
            >
                下载
            </Button>
        );
    };
    useEffect(() => {
        if (isDeleteItemSuccess) {
            notification.success({
                message: "Success",
                description: "删除成功",
            });
        }
        if (isDeleteItemError) {
            notification.error({
                message: "Error",
                description: deleteItemError?.data?.error || "删除失败",
            });
        }
    }, [isDeleteItemSuccess, isDeleteItemError])


    const handleDelete = (record) => {
        setDeleteLoading(true);
        try {
            const formData = new FormData();
            formData.append('id', record.id);
            DeleteItem(formData)
        }catch (err){
            const err_msg = err.response?.data?.errors;
            notification.error({
                message: 'Error',
                description: err_msg,
            })
        }finally {
            setDeleteLoading(false);
        }
    };

    const columns = [
        {
            title: '任务类型',
            dataIndex: ['record_type', 'description'],
            key: 'record_type'
        },
        {
            title: '上传/标识文件名',
            dataIndex: 'import_name',
            key: 'import_name',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status'
        },
        {
            title: '耗时',
            dataIndex: 'completed_time',
            key: 'completed_time',
        },
        {
            title: '提交人',
            dataIndex: ['user', 'username'],
            key: 'username',
        },
        {
            title: '提交时间',
            dataIndex: 'created_at',
            key: 'created_at',
            render: renderDateTime,
        },
        {
            title: '备注/错误信息',
            dataIndex: 'note_or_error_msg',
            key: 'note_or_error_msg'
        },
        {
            title: '操作',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    {renderDownloadButton(record)}
                    <Button type="link" loading={deleteLoading} onClick={() => handleDelete(record)}>删除</Button>
                </Space>
            ),
        },
    ];
    return (
        <div>
            <Space direction="vertical"
                size="middle"
                style={{
                    display: 'flex',
                }}>
                <AsyncManagementSearchBar
                    userData={userData}
                    recordTypeData={recordTypeData}
                    setSearchData={setSearchData}
                    setCurrentPage={setCurrentPage}
                    setPageSize={setPageSize}
                />
                <Space direction="horizontal" style={{ display: 'flex' }}>
                    <Button onClick={refetch}>
                        刷新
                    </Button>
                </Space>
                <Table
                    rowKey={(record) => record.id}
                    loading={isLoading || isFetching}
                    columns={columns}
                    dataSource={data?.results}
                    pagination={{
                        showSizeChanger: true,
                        pageSize: pageSize,
                        current: currentPage,
                        total: data ? data.count : 0,
                        onChange: (page, pageSize) => {
                            setCurrentPage(page);
                            setPageSize(pageSize);
                        },
                        onShowSizeChange: (current, size) => {
                            setCurrentPage(current);
                            setPageSize(size);
                        },
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    scroll={{ x: 1500 }}
                />
            </Space>
            <DownloadModal
                open={downloadModalVisible}
                setOpen={setDownloadModalVisible}
                record={downloadRecord}
                setRecord={setDownloadRecord}
            />
        </div>
    )
}
export default AsyncManagementPage;