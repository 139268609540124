import React, {useEffect, useState} from 'react';
import {Table, Space, Button, notification} from 'antd';
import {
    useGetBillingProcessQuery,
    useGetUserQuery,
    useGetAllCustomerUidQuery,
    useGetBillingInvoiceNamesQuery,
} from '../../features/api/apiSlice';
import BatchManagementSearchBar from './BatchManagementSearchBar';
import { renderDateTime } from '../utils';
function BatchManagementPage () {
    const [searchData, setSearchData] = useState({});
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const { data, error, isLoading, isFetching, refetch } = useGetBillingProcessQuery({...searchData, pageSize: pageSize, currentPage: currentPage});
    const { data: userData, error: userError, isLoading: userIsLoading, refetch: refetchUserData } = useGetUserQuery();
    const { data: customerData, refetch: refetchUid } = useGetAllCustomerUidQuery();
    const { data: billingInvoiceNames } = useGetBillingInvoiceNamesQuery();
    // refetch the data when the component is mounted
    useEffect(() => {
        refetch();
        refetchUserData();
        refetchUid();
    }, [])
    const columns = [
        {
            title: '导入批次编号',
            dataIndex: ['billing_invoice', 'name'],
        },
        {
            title: '客户ID',
            dataIndex: ['customer', 'uid'],
        },
        {
            title: '客户名称',
            dataIndex: ['customer', 'username'],
        },
        {
            title: '账单负责人',
            dataIndex: ['customer', 'user', 'username'],
        },
        {
            title: '主单数',
            dataIndex: 'shipment_count',
            key: 'shipment_count',
        },
        {
            title: '子单数',
            dataIndex: 'package_count',
            key: 'package_count',
        },
        {
            title: '异常子单数',
            dataIndex: 'error_package',
            key: 'error_package',
        },
        {
            title: 'Net Charge Amount',
            dataIndex: 'charge_amount',
            key: 'charge_amount',
        },
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: '提交人',
            dataIndex: ['billing_invoice', 'user', 'username'],
        },
        {
            title: '创建时间',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (date) => renderDateTime(date),
        },
        {
            title: '最新更新时间',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (date) => renderDateTime(date),
        }
    ]
    return (
        <div>
            <Space style={{ display: 'flex'}} direction='vertical'>
                <Space style={{ display: 'flex'}} direction='horizontal' size='middle'>
                    <h2>导入批次管理</h2>
                </Space>
                <BatchManagementSearchBar
                    userData={userData}
                    customerData={customerData}
                    billingInvoiceNames={billingInvoiceNames}
                    setSearchData={setSearchData}
                    setCurrentPage={setCurrentPage}
                    setPageSize={setPageSize}
                    />
                <Table
                    rowKey={(record) => record.id}
                    loading={isLoading || isFetching}
                    dataSource={data?.results}
                    columns={columns}
                    pagination={{
                        showSizeChanger: true,
                        pageSize: pageSize,
                        current: currentPage,
                        total: data?.count,
                        onChange: (page, pageSize) => {
                            setCurrentPage(page);
                            setPageSize(pageSize);
                        },
                        onShowSizeChange: (current, size) => {
                            setCurrentPage(current);
                            setPageSize(size);
                        },
                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                    }}
                    scroll={{ x: 1500 }}
                />
            </Space>
        </div>
    );
}
export default BatchManagementPage;