import {Button} from "antd";
export function CalculationDetailsPageColumns(setOpenCost, setCostContent) {
    return [
        {
            title: '状态',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Tracking Number',
            dataIndex: 'tracking_id',
            key: 'express_or_ground_tracking_id',
            sorter: true,
            render: (text, record) => {
                let fullTrk = '';
                if (!(record.rate_json['Grd Prefix'])) {
                    fullTrk = record.rate_json['Tracking Number']
                } else {
                    fullTrk = record.rate_json['Grd Prefix'] + record.rate_json['Tracking Number']
                }
                return <a
                    href={`https://www.fedex.com/wtrk/track/?action=track&trackingnumber=${fullTrk}&cntry_code=us&locale=en_us`}
                    target="_blank"
                >
                    {text}
                </a>
            }
        },
        {
            title: 'Master Trk',
            dataIndex: ['shipment', 'master_tracking_number'],
            key: 'td_master_tracking',
        },
        {
            title: '导入批次',
            dataIndex: ['billing_invoice', 'name'],
            key: 'billing_invoice_name',
        },
        {
            title: '出账批次',
            dataIndex: ['Invoice', 'name'],
            key: 'invoice_name',
        },
        {
            title: 'Service',
            dataIndex: ['logistics_service', 'name'],
            key: 'service',
        },
        {
            title: 'UID',
            dataIndex: ['customer', 'uid'],
            key: 'uid',
        },
        {
            title: 'Rated Weight',
            dataIndex: 'rated_weight',
            key: 'rated_weight'
        },
        {
            title: 'Zone',
            dataIndex: 'zone',
            key: 'zone'
        },
        {
            title: '费用',
            dataIndex: ['fee_json', 'total'],
            key: 'fee',
            render: (text, record) => {
                if (record.status === "待出账" || record.status === "已出账"){
                    return <Button type="link" onClick={() => {

                        const newModalContent = Object.entries(record.fee_json)
                            .sort(([keyA], [keyB]) => {
                            // 将 'fuel_rate' 排在最前
                            if (keyA === 'fuel_rate') return -1;
                            if (keyB === 'fuel_rate') return 1;
                            return 0;
                        }).map(([key, value], index) => {
                            let modifiedValue = value;

                            // 根据 key 的不同，修改 value
                            switch (key) {
                                case "fuel_rate":
                                    modifiedValue = `${value*100}%` // 费率
                                    break;
                            }

                            // 返回修改后的对象
                            return {
                                key: index,
                                type: key,
                                amount: modifiedValue
                            };
                        });
                        setCostContent(newModalContent);
                        setOpenCost(true);
                    }}>
                        {text}
                    </Button>
                }else{
                    return text;
                }
            }
        },
        {
            title: 'Shipment Date',
            dataIndex: 'shipment_date',
            key: 'shipment_date',
            sorter: true,
        },
        {
            title: '计费异常',
            dataIndex: 'calculation_error',
            key: 'calculation_error',
        }
    ]
}

