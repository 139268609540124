import React, { useState, useEffect } from 'react';
import { Modal, notification, Select } from 'antd';
import { useSunkCostMutation } from '../../features/api/apiSlice';
function SunkCostModal ({open, setOpen, selectedRowKeys, setSelectedRowKeys, flag=false}) {
    const [sunkCostHandler, {
        isLoading: sunkCostLoading,
        isSuccess: isSunkCostSuccess,
        isError: isSunkCostError,
        error: sunkCostError,
    }] = useSunkCostMutation();
    useEffect(() => {
        if (isSunkCostSuccess) {
            notification.success({
                message: 'Success',
                description: '标记为Sunk Cost成功',
            })
            setOpen(false);
            setSelectedRowKeys([]);
        }
        if (isSunkCostError) {
            notification.error({
                message: 'Error',
                description: sunkCostError?.data?.error ?? '标记为Sunk Cost失败',
            })
        }
    }, [isSunkCostSuccess, isSunkCostError, sunkCostError])
    const [userId, setUserId] = useState(null);
    const handleSunkCost = () => {
        const request = {
            ids: selectedRowKeys,
            to_uid: userId,
            flag: flag,
        };
        sunkCostHandler(request);
    }
    const handleCancel = () => {
        setOpen(false);
        setSelectedRowKeys([]); 
    }
    return (
        <Modal
            title="Sunk Cost"
            open={open}
            onCancel={handleCancel}
            onOk={handleSunkCost}
            confirmLoading={sunkCostLoading}
        >
            需要更改为的用户ID: &nbsp;
            <Select
                style={{ width: "50%" }}
                value={userId}
                onChange={setUserId}
            >
                <Select.Option value="0001">998</Select.Option>
                <Select.Option value="0002">997</Select.Option>
                <Select.Option value="0003">封顶</Select.Option>
                <Select.Option value="0005">自用面单</Select.Option>
                <Select.Option value="0006">Additional Weight Charge</Select.Option>
            </Select>
        </Modal>
    )
}
export default SunkCostModal;