import React, { useEffect } from 'react';
import { Modal, Form, Input } from 'antd';
function UidEditModal ({ open, setOpen, onSubmit, loading, success }) {

    useEffect(() => {
        if (success) {
            form.resetFields();
        }
    }, [success])
    const [form] = Form.useForm();
    const handleOk = () => {
        form.validateFields().then(values => {
            onSubmit(values);
        }).catch(error => {
            console.log(error);
        })
    }
    
    return (
        <Modal
            title="修改UID"
            open={open}
            onCancel={() => setOpen(false)}
            onOk={handleOk}
            centered
            confirmLoading={loading}
        >
            <Form
                form={form}
                labelCol={{
                    span: 6,
                }}
                wrapperCol={{
                    span: 18,
                }}
                style={{
                    marginTop: '20px',
                    marginLeft: '15%',
                    marginRight: '20%',
                    maxWidth: '60%'
                }}
            >
                <Form.Item label="UID" name="uid">
                    <Input />
                </Form.Item>
            </Form>
        </Modal>
    );
}

export default UidEditModal;