import React, { useEffect } from 'react';
import { Modal, Form, InputNumber , notification } from 'antd';
import { useCompulsoryPaymentMutation } from '../../features/api/apiSlice';
function CompulsoryPaymentModal({ open, setOpen, data, setSelectedRowKeys, setSelectedRows }) {
    const [form] = Form.useForm();

    const [compulsoryPayment, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useCompulsoryPaymentMutation();

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: 'Success',
                description: '强制收费成功'
            })
            setOpen(false);
            setSelectedRowKeys([]);
            setSelectedRows([]);
            form.resetFields();
        }
        if (isError) {
            notification.error({
                message: 'Error',
                description: error?.data?.error ?? '强制收费失败',
            })
        }
    }, [isSuccess, isError])
    const onFormFinish = () => {
        form.validateFields().then(values => {
            const request = {
                id: data.id,
                surcharge_error_json: values,
            }
            compulsoryPayment(request);
        }).catch(error => {
            console.log(error);
        })
    }

    // data.surcharge_error_json is an object
    // for each key, the form should have a field for inputting its price
    const renderForm = () => {
        if (!data?.surcharge_error_json) return null;
        const keys = Object.keys(data.surcharge_error_json);
        return (
            <Form form={form} style={{ width: '80%', marginTop: '10px' }} onFinish={onFormFinish}>
                {keys.map((key, index) => (
                    <Form.Item key={index} name={key} label={key}>
                        <InputNumber prefix="$" suffix="USD" />
                    </Form.Item>
                ))}
            </Form>
        )
    }
    return (
        <Modal
            title="强制收费"
            open={open}
            setOpen={setOpen}
            onCancel={() => setOpen(false)}
            onOk={onFormFinish}
            confirmLoading={isLoading}
        >
            请填写以下费用,不收费的项目请填写为0

            {renderForm()}
        </Modal>
    )
}
export default CompulsoryPaymentModal;