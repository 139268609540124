// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dynamic-delete-button {
    position: relative;
    top: 4px;
    margin: 0 8px;
    color: #999;
    font-size: 24px;
    cursor: pointer;
    transition: all 0.3s;
  }
  .dynamic-delete-button:hover {
    color: #777;
  }
  .dynamic-delete-button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }`, "",{"version":3,"sources":["webpack://./src/components/errorHandling/MatchZZZModal.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,aAAa;IACb,WAAW;IACX,eAAe;IACf,eAAe;IACf,oBAAoB;EACtB;EACA;IACE,WAAW;EACb;EACA;IACE,mBAAmB;IACnB,YAAY;EACd","sourcesContent":[".dynamic-delete-button {\n    position: relative;\n    top: 4px;\n    margin: 0 8px;\n    color: #999;\n    font-size: 24px;\n    cursor: pointer;\n    transition: all 0.3s;\n  }\n  .dynamic-delete-button:hover {\n    color: #777;\n  }\n  .dynamic-delete-button[disabled] {\n    cursor: not-allowed;\n    opacity: 0.5;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
