import React, { useEffect } from 'react';
import { Modal, notification } from 'antd';
import { useDeleteClientMutation } from '../../features/api/apiSlice';
function ClientDeleteModal({open, setOpen, selectedRowKeys, selectedRows, setSelectedRowKeys, setSelectedRows}) {
    const [deleteClient, { isLoading, isError, error, isSuccess }] = useDeleteClientMutation();
    useEffect(() => {
        if (isError) {
            if (error.status === 400) {
                notification.error({
                    message: "Error",
                    description: '客户已删除,已刷新页面',
                });
                setOpen(false);
            } else {
                notification.error({
                    message: "Error",
                    description: "Unknown error",
                });
            }
        }
    }, [isError])

    useEffect(() => {
        if (isSuccess) {
            notification.success({
                message: "Success",
                description: "客户信息删除成功",
            });
            setOpen(false);
            setSelectedRowKeys([]);
            setSelectedRows([]);
        }
    }, [isSuccess])
    const handleOk = () => {
        deleteClient({"ids": selectedRowKeys});
    }
    const handleCancel = () => {
        setOpen(false);
    }
    return (
            <Modal
                title="删除客户"
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="删除"
                cancelText="取消"
                okButtonProps={{ danger: true }}
                confirmLoading={isLoading}
            >
                <p>确定要删除客户 {selectedRows.map((row) => `${row.uid}`).join(',')} 的全部信息吗?</p>
            </Modal>
    )
}
export default ClientDeleteModal;